.shippingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 50px;
}

.previousContainer {
    display: flex;
    width: 80%;
    align-items: flex-start;
    padding: 20px;
}

.previousBtn {
    cursor: pointer;
    position: relative;
    color: #555;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    background-color: transparent;
    border: none;
}

.previousIcon {
    margin-right: 5px;
}


.shippingCard {
    background: #F7FAFC;
    padding: 20px;
    width: 80%;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}


.inputLine{
    display: flex;
}

@media (max-width: 1000px) {
    .inputLine {
        flex-direction: column;
    }
}

.inputLineSame{
    display: flex;
}

.inputContent{
    display: flex;
    flex: 1;
    margin: 20px;
    height: calc(2.25rem + 5px);
    justify-content: center;
    align-items: center;
}

.inputDateContainer {
    flex: 12;
}

.inputDate {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    background-color: white !important;
}

.textDanger{
    color: red;
    margin-left: 20px;
    margin-top: -10px;
}

.inputDate:focus{
    outline: none;
}

.inputField {
    flex: 1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    height: calc(2.25rem + 5px);
    margin-top: 5px;
}

.inputGroup{
    flex: 1;
    margin: 10px 20px;
}

.input {
    display: inline-block;
    flex: 12;
    box-sizing: border-box;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    background-color: white !important;
}

@media (max-width: 800px) {
    .input {
        flex: 7 !important;
    }
}

.input:focus {
    outline: none;
}

.inputIcon {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.btn {
    background: #51276C;
    padding: 10px 25px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 1rem;
    display: block;
    margin: 20px auto 10px;
}

.btn:hover {
    background: #4C0C78;
    cursor: pointer;
}

.disabledBtn {
    background-color: #C4C4C4;
    padding: 10px 25px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 1rem;
    display: block;
    margin : 10px auto;
}

.btnFemale {
    border-radius: 25px 0 0 25px;
}

.btnMale {
    border-radius: 0 25px 25px 0;
}

.btnSelected {
    background: #51276C;
    padding: 10px 25px;
    height: calc(2.25rem + 2px);
    color: #FFFFFF;
    border: none;
    font-size: 1rem;
    display: block;
}

.btnNotSelected {
    background: #C4C4C4;
    padding: 10px 25px;
    color: #E5E5E5;
    border: none;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    display: block;
}



