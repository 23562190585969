.loginContainer {
    height: 90vh;
    margin-top: 80px;
}

/* card */

.loginCard {
    padding: 20px;
    width: 500px;
    margin: 100px 0 50px;
    top: -30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 800px) {
    .loginCard {
        width: 80%;
        margin: 20px 0 0;
    }
}

.loginBtn {
    margin : 20px auto;
}

.linkUsePwd {
    margin-bottom: 40px;
}

.linkRegister{
    margin: 5px auto;
}


/* footer */
.footer {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* message d'erreur */
.error {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #CE3534;
}

.inputGroup {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
}


.loaderBtn {
    position: relative;
}

.loaderBtn div {
    color: transparent;
}

.loadingLoginBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
