.container {
    margin: calc(75px + 9.6rem) auto 9.6rem;
    padding:0 2rem;
    max-width: 1140px;
}

.subContainer {
    display: flex;
    flex-direction: row;
    gap: 8rem;
}

.title {
    font-size: 3.5rem;
    margin: 0;
}

.subTitle {
    font-size: 2.4rem;
    margin: 0;
}

p {
    margin: 1rem 0 2rem;
    font-size: 1rem;
}

@media (max-width: 800px) {
    .container{
        margin: calc(75px + 3rem) auto 3rem;
    }
    .subContainer {
        flex-direction: column;
        text-align: center;
        gap: 2rem;
    }
    .subContainer>div{
        gap: 2rem;
    }
    .title {
        font-size: 2.2rem;
        line-height: 2.5rem;
    }
    .subTitle{
        font-size: 1.7rem;
        line-height: 2rem;
    }
}