.cardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

.subContainer {
    margin: 10px;
    display: flex;
}

@media (max-width: 800px) {
    .subContainer {
        flex-direction: column;
    }
}

.infos {
    padding-left: 20px;
    display: flex;
    justify-content: center;
}

.title {
    align-self: flex-start;
    margin-bottom: 30px
}

.text {
    margin: 40px auto;
    font-size: 1.2rem;
}

.paymentCardImg {
    object-fit: contain;
    height: 80%;
    width: 80%;
}

.containerCardImg {
    border: 1px solid rgb(206, 212, 218);
    padding: 0 4px;
    width: auto;
    aspect-ratio: 16 / 9;
    border-radius: 4px;
    height: 1.8rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}