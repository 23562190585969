.containerBasket {
    padding: 0px 30px 0px 0px;
    border-radius: 5px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    flex: 2;
    margin-bottom: 20px;
}

.price {
    font-size: 1.5rem;
    font-weight: 600;
}

.productName {
    margin: "0";
    text-align: left;
    font-weight: 500;
    font-size: 1.1rem;
    text-align: left;
}

.lastItem {
    margin-bottom: 80px;
}

@media screen and (max-width: 768px) {

    .containerBasket {
        padding: 0;
        border-top: 1px solid #E6E6E6;
        padding-top: 5px;
        margin-bottom: 5px;
    }

    .containerTitreQty {
        align-items: flex-start;
    }

    .price {
        margin: 0 auto;
        font-size: 1rem !important;
    }

    .productName {
        font-size: .8rem;
    }
}