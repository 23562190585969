.openStat {
    width: 40px;
    height: 40px;
    transition: width 1s ease-in-out, height 1s ease-in-out;
    border-radius: 50px;
    background-color: rgb(224, 224, 224);
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardStat {
    overflow: hidden;
    width: 400px;
    height: 200px;
    border-radius: 15px;
    background-color: rgb(224, 224, 224);
    transition: width 1s ease-in-out, height 1s ease-in-out;
}

.containerPopUp {
    position: absolute;
    width: fit-content;
    height: fit-content;
    bottom: 445px;
    right: 50px;
    cursor: pointer;
}

.closeButton {
    position: absolute;
    right: 10px;
    width: -webkit-fit-content;
    width: fit-content;
    top: 6px;
    color: var(--var-black-color);
}


.titlePopUp {
    text-align: center;
}

.cardPrice {
    flex: .8 !important;
    gap: .5rem !important;
}

.containerPrice {
    display: flex;
    gap: .5rem;
}

.titleFraiLivraison {
    font-weight: 700;
    display: flex;
    gap: .5rem;
    display: flex;
    justify-content: space-between;
}

.containerPriceLivraison {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.containerPrix {
    justify-content: flex-start !important;
    display: flex !important;
    flex-direction: column !important;
    text-align: left !important;
}

.price {
    display: flex;
    gap: .3rem;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 700;
}

.priceLivraison {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: 500;
}

.retrait {
    display: flex;
    justify-content: space-between;
}

.retrait svg {
    width: 25px !important;
}

.sousTexte {
    margin: 1rem 0;
}

.greenText {
    color: #6fa939;
}

.customSelect {
    width: 150px;
    padding: 3px;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.25rem;

}

.containerStep {
    min-width: 50px;
}

.basketItems {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.basketItems>div:nth-child(2n-1):last-of-type {
    grid-column: 1 / -1;
  }

.centimePrice {
    font-size: .9rem;
}

.nbArticle {
    position: relative;
    width: 100%;
    text-align: right;
    margin-top: 1rem;
}

.nbArticle:after {
    content: '';
    height: 2px;
    width: 50%;
    position: absolute;
    right: 0;
    top: 37px;
    background-color: rgb(223, 226, 228);
    opacity: 0.8;
    border-radius: 20px;
}

.containerTextIcon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

@media screen and (max-width: 768px) {
    .containerPriceLivraison {
        flex-direction: column;
        gap: 10px;
    }

    .basketItems {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 0;
    }

    .cardPrice {
        align-items: flex-start !important;
    }

    .nbArticle {
        text-align: start;
    }

    .nbArticle:after {
        right: auto;
        left: 0;
    }

    .titleFraiLivraison {
        flex-direction: column;
    }

    .retrait {
        align-items: center;
        margin-left: -7px;
        margin-right: -3px;
    }

    .priceLivraison{
        font-size: 1rem;
    }
}
