.navbar {
  background: #ffffff;
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 10006;
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
}

/* Logo dans la navbar */

.logoContainer {
  text-align: left;
  display: inline-block;
  height: 50px;
  padding: 15px 60px 15px 20px;
}

@media screen and (max-width: 960px) {
  .logoContainer {
    padding: 15px 20px 19px 20px;
    flex: 1;
    height: 45px;
  }

  
}

.logo {
  aspect-ratio: 500/160;
  height: 80%;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .logo {
    width: 100%;
    object-fit: contain;
    object-position: left;
    height: 45px;
  }
}

/* Liens dans la navbar */

.link {
  color: #000;
  font-size: 1.1rem;
  /* height: 75px; */
  padding: 0 24px;
  text-decoration: none;
  gap: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  font-weight: 500;
}

.link:hover {
  text-decoration: none;
  cursor: pointer;
}

.btn {
  padding: 10px 20px !important;
  font-size: 1.1rem !important;
}

.redBtn {
  background-color: #ce3534;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  font-weight: 500;
  border-radius: 70px;
  font-size: 1.2rem;
  height: auto;
  color: white;
  margin-left: 15px;
  text-decoration: none;
}

.redBtn:hover {
  text-decoration: none;
  background-color: #c02727;
  color: white;
}

.leftBtn {
  right: 20px;
  position: absolute;
}

.mobileUserBtn {
  height: 70% !important;
  border: none;
  background: none;
  color: #571088;
  width: auto;
  padding: 7px 5px 3px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .mobileUserBtn {
    display: flex;
    position: relative;
    padding: 7px 6px 3px 0 !important;
  }

  .mobileUserBtnMarginLeft {
    margin-left: 2px;
    margin-bottom: 2px;
  }

  .leftBtnContainer {
    flex-direction: row-reverse !important;
    justify-content: flex-start !important;
  }
}

.mobileUserBtn:hover {
  color: #4c0c78;
}

.leftBtnContainer {
  right: 20px;
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.profileBtn {
  display: grid;
  grid-template-columns: 34px 0fr;
  transition: grid-template-columns 0.5s ease-out;
  align-items: center;
  background: #e6e6e8;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  font-weight: 500;
  padding: 4px 4px 4px 4px;
  border-radius: 70px;
  font-size: 1rem;
  height: 36px;
  color: white;
  border: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  margin-left: auto;
  cursor: pointer;
  overflow: hidden;
}

.profileBtn span {
  transition: padding 0.3s, opacity 0.3s;
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  font-weight: 500;
  width: auto;
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
}

.profileBtn:hover {
  grid-template-columns: 34px 1fr;
}

.profileBtn:hover span {
  padding-right: 10px;
  padding-left: 0.5rem;
  opacity: 1;
}

.iconUser {
  background-color: #571088;
  border-radius: 50px;
  padding: 10px;
}

.iconLoad {
  animation: iload 1s infinite linear;
  margin: auto;
}

@keyframes iload {
  to {
    transform: rotate(1turn);
  }
}

@media screen and (max-width: 960px) {
  .leftBtnContainer {
    position: relative;
    flex: 1;
    margin-right: 17px;
    right: 0;
    gap: 1.3rem;
    margin-left: 1px;
  }
}

.menu {
  font-size: 1.8rem !important;
  border: none;
  background: none;
}

@media screen and (max-width: 960px) {
  .menu {
    padding: 1px 6px;
    margin-top: 1px;
    width: 35px;
  }
}

.dropdownMenu {
  z-index: 10005;
  background: white;
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 10%);
}

.mobileLink {
  color: #000000;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  text-decoration: none;
}

.mobileBtn {
  padding: 10px 20px !important;
  font-size: 1.1rem !important;
}

.mobileRedBtn {
  background-color: #ce3534;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  font-weight: 500;
  border-radius: 70px;
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
}

.mobileMenuItem {
  border-style: solid;
  border-color: rgba(132, 132, 132, 0.15);
  border-width: 0;
  border-bottom-width: 1px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}

.dropdownMenuConnected {
  z-index: 10010;
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  text-align: right;
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 10%);
  right: 0;
  border-top: 1px solid #ddd;
}

.mobileDropdownMenuConnected {
  z-index: 10005;
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 10%);
  width: 100%;
}

.basketShopping {
  background-image: url("../../assets/basket-shopping.svg");
  width: 37px;
  height: 37px;
  background-repeat: no-repeat;
}

.dialogContainer {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Fond semi-transparent pour assombrir l'arrière-plan */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10009;
}

.radioBtn {
  align-self: center;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  justify-content: center;
}

.customCheckbox {
  width: 50px;
  height: 50px;
  /* background-color: white; */
  border: 3px solid #e4e4e4;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s ease;
  top: -3px;
  left: -3px;
  border-radius: 10px 0;
}

.radioBtn:checked + .checkboxLabel .customCheckbox::after {
  content: "";
  position: absolute;
  top: 8px;
  left: 17px;
  width: 13px;
  height: 19px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.customCheckbox:not(:checked) {
  background-color: #e4e4e4;
}

.radioBtn:not(:checked) + .checkboxLabel .customCheckbox::after {
  content: "";
  position: absolute;
  top: 11px;
  left: 13px;
  width: 17px;
  height: 17px;
  background-color: white;
  border-radius: 50px;
  border: solid #e3e3e3;
  /* border-width: 0 3px 3px 0; */
  /* transform: rotate(45deg); */
}

/* Change le background color de la case cochée */
.radioBtn:checked + .checkboxLabel .customCheckbox {
  border-color: #6fa939;
  background-color: #6fa939;
}

.containerPrix {
  margin-right: 30px;
  display: flex;
  align-items: flex-end;
}

.ContainerPanier {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* background-color: #f7fafc; */
  border-radius: 15px;
  border: 3px solid #e4e4e4;
  flex: 1;
  cursor: pointer;
}

.titleDialogBox {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  font-weight: 700;
}

.dialogBox {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Ombre légère */
  z-index: 10010;
  position: fixed;
  top: 100px;
  /* overflow-y: scroll; */
}

.dialogBox > div {
  margin: 10px 10px 25px 10px;
  font-size: 1.2rem;
}

.containerChoice {
  display: flex;
  gap: 2rem;
}

.containerPrixArticle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
}

.containerResumeBasket {
  display: flex;
}

.whiteShadow {
  mask: linear-gradient(180deg, #0000, #000 0% 50%, #0000);
}

.containerOneChoice {
  position: relative;
  overflow-y: hidden;
  transition: height 1s ease-in-out;
  max-height: 300px;
  overflow-y: scroll;
}

.containerOneChoice::-webkit-scrollbar {
  display: none;
}

.buttonPanier {
  background-color: transparent;
  border: 1px solid transparent;
  align-self: flex-start;
  border-radius: 10px;
  display: flex;
  width: 100%;
  height: 3rem;
  align-items: center;
  gap: 1rem;
  padding: 0 0;
  margin: 7px 0;
}
.containerquestion {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.buttonChoice {
  position: absolute;
  top: 28px;
  right: 32px;
  font-family: "Segoe UI SemiBold", sans-serif;
  border-radius: 50px;
  color: white;
  padding: 10px 20px;
  font-weight: 700;
  border: none;
  background-color: #571088;
  /* bottom: -29px; */
  cursor: pointer;
  font-size: 1rem;
}

.buttonChoice:hover {
  background-color: #4c0c78;
}

.arrow {
  transition: transform 0.3s ease-in-out;
  font-size: 1rem;
}


/* GESTION AFFICHAGE HOVER ABSKET */

.overlayBasket {
  z-index: -1;
}


.overlayBasket:global(.visible) {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  inset: 0;
  z-index: 100001;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
}

.basketArticle {
  display: none;
  position: fixed;
  top: 75px;
  left: auto;
  right: 0;
  z-index: 1000000;
  min-width: 350px;
  width: fit-content;
  visibility: hidden;
  /* height: 0; */
  opacity: 0;
  display: flex;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto";
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  flex-direction: column;
  padding: 1rem;
  margin: 20px;
  height: fit-content;
  transition: visibility 0.3s, opacity 0.3s linear;
}

.basketArticle:global(.visible) {
  opacity: 1 !important;
  visibility: visible !important;
}

.basketArticle:global(.emptyBasket){
  padding: 0 1rem !important;
  height: 250px;
  overflow: hidden;
}

.basketArticle:global(.emptyBasket>div){
  font-size: 1.1rem;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  text-align: right;
  padding: 5rem 1.5rem 0 0;
}

.basketArticle:global(.emptyBasket>span){
  font-size: 1rem;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  text-align: right;
  padding: .5rem 1.5rem 0 0;
}

.basketArticle:global(.emptyBasket img){
  position: absolute;
  left: -50px;
  top: -0px;
  width: 300px;
}

.loader{
  min-height:50px;
  margin: 20px auto;
  background-color: transparent;
  align-content: center;
}

.loader > div {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #571088;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
  margin:auto;
}
@keyframes l3 {to{transform: rotate(1turn)}}


.containerArticle {
  max-height: 317px;
  overflow-y: auto;
  scrollbar-width: none;
  mask: linear-gradient(180deg,#000,#000 80%,#0000);
}

.containerArticle::-webkit-scrollbar {
  display: none;
}

.containerItemBasket {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin:.5rem 0 1.5rem ;
}

.imgBasket {
  width: 125px;
  flex: 1;
  object-fit: contain;
}

.containerNomQty {
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 2rem 0 .5rem;
  color: #000;
  max-width: 200px;
}

.containerNomQty select {
  width: fit-content;
  font-weight: 500;
  font-family: Arial;
}

.containerNomQty a {
    color: #571088;
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto";
    font-weight: 500;
}

.TrashCan {
  cursor: pointer;
}

.containerPrixBasket {
  display: flex;
  color: #7a7a7a;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.containerPrixBasket span {
  color: black;
}

.containerPrixBasket svg {
 opacity: .4;
}

.containerButton {
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.containerButton a:first-child {
  font-weight: 500;
  border: 1px solid #571088;
  color: #571088;
  font-size: 1rem;
  background-color: transparent;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  box-sizing: content-box;
  padding-block: 0;
  padding-inline: 0;
  cursor: pointer;
  z-index: 2;
  height: 2rem;
  padding: .2rem 0.9rem;
  position: relative;
  text-decoration: none;
}

.containerButton a:last-child {
  border: 1px solid #571088;
  color: white;
  font-size: 1rem;
  background-color: #571088;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  box-sizing: content-box;
  padding-block: 0;
  padding-inline: 0;
  cursor: pointer;
  z-index: 2;
  height: 2rem;
  padding: .2rem 0.9rem;
  position: relative;
  text-decoration: none;
  font-weight: 500;
}

.containerButton a:hover:first-child {
  background-color: #571088;
  color: white;
}

.containerButton a:hover:last-child{
  background-color: white !important;
  color: #571088 !important;
}

.selectQuantite {
  cursor: pointer;
  margin: 1rem 0;
  padding: .2rem .4rem;
  border-color: #e6e6e8;
  border-radius: 7px;
  font-size: 13.3px;
}

.price {
  padding: 0.6rem 1rem;
  display: flex;
  color: black;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto";
  align-items: flex-end;
}

.euro {
  font-size: 1.7rem;
}

.centimePrice {
  margin-bottom: 4px;
  font-size: 1rem;
}

.centime {
  margin-bottom: 3px;
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .containerChoice {
    flex-direction: column;
  }

  .buttonChoice {
    top: auto;
    bottom: 32px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    position: initial;
    z-index: 100;
    order: 3;
  }

  .dialogBox {
    overflow-y: scroll;
    position: fixed;
    bottom: 0;
    top: 260px;
    border-radius: 20px 20px 0 0;
    transform: translateY(100%);
    transition: all 1s ease-in-out;
  }

  .dialogBox > div {
    font-size: 1rem;
  }

  .containerPrixArticle {
    font-size: 1rem;
    margin: 0 10px 0 40px;
  }

  .containerPrixArticle > div:first-child {
    margin-right: 50px !important;
  }
  .checkboxLabel {
    position: absolute;
    top: 17px;
    left: 10px;
  }

  .centime {
    margin-bottom: 2px;
    font-size: 0.7rem;
  }

  .radioBtn:checked + .checkboxLabel .customCheckbox::after {
    top: 2px;
    left: 7px;
    width: 7px;
    height: 10px;
  }

  .radioBtn:not(:checked) + .checkboxLabel .customCheckbox::after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
  }
  .containerOneChoice {
    height: 0;
  }

  .ContainerPanier {
    padding: 0.5rem;
    gap: 0rem;
    border-width: 3px !important;
    position: relative;
  }

  .dialogBox > div {
    gap: 0.5rem;
  }

  .containerChoice {
    gap: 1rem;
  }

  .customCheckbox {
    width: 25px;
    height: 25px;
    top: -17px;
    left: -10px;
  }

  .buttonPanier {
    align-items: flex-start;
    height: auto;
    display: flex;
    align-items: center;
  }

  .mobileMenuItem {
    min-width: none;
  }

  /* GESTION HOVER BASKET MOBILE */

  .basketArticle {
    top: auto;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    position: fixed;
    transition: transform 0.3s ease-in-out;
    padding: 1rem;
    height: fit-content;
    width: calc(100% - 2rem);
    border-radius: 12px 12px 0 0;
    visibility: visible;
    opacity: 1;
    margin: 0px;
    min-width: auto;
}

.basketArticle:global(.visible) {
    margin: 0;
    transform: translateY(0%);
}

.containerItemBasket {
  align-items: flex-start;
}


.imgBasket {
  width: 100px;
  margin-right: 10px;
}

.containerPrixBasket>span>span {
  font-size: 1.1rem;
}

.containerButton {
  gap: 1rem;
}

.leftBtnContainer{
  gap:.45rem;
}

.menu :global(.svg-inline--fa){
  height: 0.7em;
}

.logoContainer{
  padding: 18px 20px 14px;
}

.logo{
  height: 36px;
}

.iconUser{
  padding: 6px;
}

.basketShopping {
  height: 28px;
  width: 28px;
}

.profileBtn {
  background: transparent;
  position: relative;
  left: 6px;
}

}
