.cardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
}
.currentRelay{
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    display: flex;
    gap: 1rem;
}

@media screen and (max-width: 768px) {
    .currentRelay{
        position:unset;
        flex-flow: row-reverse;
    }
}