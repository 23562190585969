.inputLine{
    display: flex;
}

@media (max-width: 1000px) {
    .inputLine {
        flex-direction: column;
    }
}

.inputGroup {
    flex: 1;
    margin: 10px 20px;
}

.input {
    display: inline-block;
    flex: 12;
    box-sizing: border-box;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    background-color: white !important;
}

input[type="radio"], label{
    cursor:pointer;
}

input[type="radio"]{
    accent-color: #571088;
}

@media (max-width: 800px) {
    .input {
        flex: 7;
    }
}

.input:focus {
    outline: none;
}

.inputIcon {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

.inputField {
    flex: 1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    height: calc(2.25rem + 5px);
    margin-top: 5px;
}

.eyeIcon {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    color: #495057;
}

.iconHelp {
    margin-left: 5px;
    color: #888;
}


.inputGroupBirthGender{
    flex: 1;
    display: flex;
    flex-direction: row;
    margin: auto 20px;
}

.gender button{
    font-size: 1.15rem;
}

@media (max-width: 800px) {
    .inputGroupBirthGender {
        flex-direction: column;
    }
}


.inputGroupBirth {
    flex: 3;
    margin: 10px 0 10px 20px;
}

@media (max-width: 800px) {
    .inputGroupBirth {
        margin-left: 0;
    }
}



.inputFieldBirth {
    box-sizing: border-box;
    position: relative;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    margin: 5px 0 0;
    height: calc(2.25rem + 5px);
}

.inputContent{
    display: flex;
    flex: 1;
    margin: 0;
    height: calc(2.25rem + 5px);
    justify-content: center;
    align-items: center;
}

.inputDateContainer {
    flex: 12;
}

.inputDate {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    background-color: white !important;
}

.inputDate:focus{
    outline: none;
}

.textDanger{
    color: red;
    margin-left: 20px;
    margin-top: -10px;
}

.disabledBtn {
    background: #C4C4C4;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height:auto;
    color: white;
    border: none;
    text-decoration: none;
    cursor: default !important;
}

.defaultBtn:hover {
    background: #C4C4C4;
    text-decoration: none;
    color: white;
}

.inputLineSame{
    display: flex;
}

.checkbox {
    margin: 0 25px;
    height: 15px;
    width: 15px;
    border: #1C4CBD !important;
    background-color: #1C4CBD !important;
}

input[type=checkbox] {
    cursor: pointer;
    visibility: hidden;
    height: 15px;
    width: 15px;
    -moz-appearance:initial;
}

input[type=checkbox]::after {
    content: " ";
    background-color: #fff;
    color: #571088;
    visibility: visible;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

input[type=checkbox]:checked:after {
    content: "\2714";
    font-weight: bold;
}

/** Boutons pour choisir le sexe **/

.inputGroupGender {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.gender {
    flex: 1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    height: calc(2.25rem + 5px);
    justify-content: flex-start;
    align-items: center;
    margin: 0;
}

.btnSelected {
    background: #571088;
    padding: 10px 25px;
    height: calc(2.25rem + 2px);
    color: #FFFFFF;
    border: none;
    font-size: 1rem;
    display: block;
}

.btnNotSelected {
    background: #C4C4C4;
    padding: 10px 25px;
    color: #E5E5E5;
    border: none;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    display: block;
}

.btnFemale{
    border-radius: 25px 0 0 25px;
}

.btnMale{
    border-radius: 0 25px 25px 0;
}

.btnFemale:hover .btnMale:hover {
    background: #4C0C78;
    cursor: pointer;
}


/** Bouton à la fin du formulaire **/

.btnContainer {
    display: flex;
    justify-content: center;
    align-items: center
}
