button {
    cursor: pointer;
}

.defaultBtn {
    background: #571088;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height: fit-content;
    color: white;
    border: none;
    text-decoration: none;
    text-align: center;
}

.defaultBtn:hover {
    background: #4c0c78;
    text-decoration: none;
    cursor: pointer;
    color: white;
}

.secondaryBtn {
    border: 1px solid #571088;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    font-weight: 500;
    padding: 9px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height: auto;
    color: #571088;
    text-decoration: none;
    background: none;
    text-align: center;
}

.secondaryBtn:hover {
    background: #571088;
    color: white;
    border: 1px solid #571088;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 1020px) {

    .defaultBtn,
    .secondaryBtn {
        font-size: 1rem;
    }
}

.defaultLink {
    color: #571088;
    font-weight: 500;
    background: none;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    font-size: 1rem;
    text-decoration: none;
}

.defaultLink:hover {
    color: #4c0c78;
    text-decoration: underline;
    cursor: pointer;
}

.globalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1020px;
    margin: 10px auto 50px auto;
    min-height: 400px;
}

@media (max-width: 1020px) {
    .globalContainer {
        margin-top: 0;
        margin-right: 10px;
        margin-left: 10px;
    }
}

.card {
    background: #f3f6f8;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    text-wrap: balance;
}

.cardMore {
    padding: 30px;
    flex: 1;
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 20%);
    border-radius: 5px;
    display: flex;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column
}


.bulle {
    margin: 30px 20px;
    /*border: 0.1rem solid #6fa939;*/
    background: #f3f6f8;
    font-size: 90%;
    color: black;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 1fr auto;
}

.bulle>.iconBulle {
    -webkit-clip-path: polygon(-1px -1px, 0 100%, 80% 100%, 100% 50%, 80% -1px);
    clip-path: polygon(-1px -1px, 0 100%, 80% 100%, 100% 50%, 80% -1px);
    background: #6fa939;
    padding: 15px 35px 15px 30px;
    border-radius: 0.2rem 0 0 0.2rem;
    font-size: 200%;
    color: white;
    display: flex;
    align-items: center;
}

.bulle>.iconText {
    padding: 15px;
}

.badge {
    border-radius: 4px;
    padding: 3px 10px;
    color: white;
    font-size: 16px;
    font-weight: normal;
}

.childCard {
    flex: 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.cardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 960px) {}

.customSelect {
    width: 150px;
    padding: 3px;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.25rem;

}

.containerStepAndContent {
    display: flex;
    flex-direction: row;
}

.stepLine {
    width: 7px;
    height: 107%;
    background-color: #571088;
    position: relative;
    border-radius: 50px;
    color: #571088;
}

.stepLine.last{
    height: 100%;
}


.greenDots::before {
    content: "";
    position: absolute;
    left: 12px;
    top: 8px;
    width: 12px;
    height: 16px;
    border: solid rgb(111, 169, 57);
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
}

.purpleDots {
    width: 10px;
    height: 10px;
    background-color: #571088;
    border-radius: 50%;
    display: inline-block;
}

.greyDots {
    width: 10px;
    height: 10px;
    background-color: #7a7a7a;
    border-radius: 50%;
    display: inline-block;
}

.stepCircleText {
    left: 150px !important;
    width:  calc(1020px - 52px) !important;
    margin: auto;
    border: none !important;
    left: 42px !important;
    position: absolute;
}

.stepText {
    font-size: 100% !important;
    padding: 10px;
    width: 100%;
}

.stepCircle {
    max-width: 993px;
    position: absolute;
    left: -18px;
    top: 43px;
    width: 40px;
    height: 40px;
    background-color: white;
    border: 2px solid #571088;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stepLineGrey {
    background-color: #7a7a7a;
    height: 115px !important;
}

.stepLineGreen {
    background-color: rgb(111, 169, 57);
    height: 115px !important;
    color: black !important;
}

.stepLineGrey div {
    border: #7a7a7a 2px solid;
}

.stepLineGrey div div {
    color: #7a7a7a;
    border: none;
}

.stepLineGreen div {
    border: rgb(111, 169, 57) 2px solid;
}

.stepLineGreen div div {
    color: rgb(111, 169, 57);
    border: none;
}

.containerOneStep {
    margin-bottom: -5px;
    min-width: 52px;
    position: relative;
    z-index: 10;
}

.titleStep {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1.3rem;
}

.containerStepAndBasket {
    display: flex;
}

.basketContainer {
    margin-top: 110px;
    width: 100%;
}

.oneStepText {
    padding: 30px 0 30px 30px;
}

/* image cb et livraison */

.imageItemContainer {
    display: flex;
    gap: 0.8rem;
    align-items: center;
}

.imageContainer {
    aspect-ratio: 16/9;
    border-radius: 0.3rem;
    overflow: hidden;
    height: 1.8rem;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageContainer img {
    object-fit: contain;
    height: 80%;
    width: 80%;
}

.buttonNextStep {
    align-self: flex-end;
}

.containerWithBtn {
    justify-content: space-between;
}

.containerTextImage {
    display: flex;
    gap: 30px;
}

.headerStep {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    align-items: center;
    max-width: 1020px;
    margin: 30px auto 10px;
}

.headerStepPrice {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
}

.littleBtn {
    padding: 5px 20px !important;
    font-size: 1rem !important;
    min-width: 111.89px;
    min-height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headernbArticlePrice {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: .5rem;
}

.btnBottomPage {
    margin-top: 30px;
}

.greyImage {
    filter: grayscale(1);
}

.containerImage {
    display: flex;
    align-items: center;
}

.contenuImage {
    width: auto;
    height: 40px;
    object-fit: cover;
}

.singleItem {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.articleName {
    color: black;
    font-size: .8rem;
    font-weight: normal;
}

.articleQty{
    position:absolute;
    top:-10px;
    right:-10px;
    background-color: white;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    font-size: .85rem;
    padding: 1px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 4px 0px;
}

.selectPointBtn {
    padding: 6px 20px;
    background: #571088;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 70px;
    height: fit-content;
    color: white;
    text-decoration: none;
    text-align: center;
    border: none;
    cursor: pointer;
}

.selectPointBtn:hover {
    background: #4c0c78;
}

.containerTitleIcon {
    display: flex;
    gap: 1rem;
    min-width: 395px;
}

.tooltipContainer {
    position: relative;
}

.emptyBasket {
    display: flex;
    justify-content: center;
    min-height: 400px;
    margin: 20px auto 50px;
    align-content: center;
    align-items: center;
}

.emptyBasket>div {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
}

.emptyBasket>div>a {
    margin-top: 20px;
}

.emptyBasket>div>img {
    height: 320px;
}

.containerBtnError {
    position: relative;
}

.arianeSkeleton {
    background: linear-gradient(90deg, rgba(100, 100, 100, 0.1) 0%, #fff 20%, rgba(100, 100, 100, 0.1) 40%);
    animation: leftToRight 1s infinite reverse;
    background-size: 200%;
    min-height: 20px;
    width: 200px !important;
    border-radius: 15px;
}

h1.whiteSkeleton {
    background: linear-gradient(90deg, rgba(255, 255, 255, .5) 0%, rgba(255, 255, 255, .7) 50%, rgba(255, 255, 255, .5) 100%);
    animation: leftToRight 1s infinite reverse;
    background-size: 200%;
    border-radius: 25px;
    min-height: 30px;
    width: 250px;
    margin-top: 31px !important;
}

@keyframes leftToRight {
    0% {
        background-position: -100% 0;
    }

    100% {
        background-position: 100% 0;
    }
}

.loaderNextStep {
    width: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
    flex: none !important;
    cursor: default;
}

.littleLoaderNextStep {
    width: 6px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5Little 1s infinite linear alternate;
    flex: none !important;
    cursor: default;
}

.btnNextStep {
    display: flex;
    min-width: 237.66px;
    min-height: 46px;
    justify-content: center;
    align-items: center;
}

@keyframes l5 {
    0% {
        box-shadow: 20px 0 white, -20px 0 transparent;
        background: white
    }

    33% {
        box-shadow: 20px 0 white, -20px 0 transparent;
        background: transparent
    }

    66% {
        box-shadow: 20px 0 transparent, -20px 0 white;
        background: transparent
    }

    100% {
        box-shadow: 20px 0 transparent, -20px 0 white;
        background: white
    }
}

@keyframes l5Little {
    0% {
        box-shadow: 10px 0 white, -10px 0 transparent;
        background: white
    }

    33% {
        box-shadow: 10px 0 white, -10px 0 transparent;
        background: transparent
    }

    66% {
        box-shadow: 10px 0 transparent, -10px 0 white;
        background: transparent
    }

    100% {
        box-shadow: 10px 0 transparent, -10px 0 white;
        background: white
    }
}

.nextStepText {
    color: white !important;
}

.littleDefaultLink {
    margin: 0 3rem 0 0;
}

.btnValiderPayment {
    min-width: 173.19px;
}

@media screen and (max-width: 768px) {

    .containerTitleIcon {
        min-width: 175px;
    }

    .titleStep {
        font-size: 1rem;
    }

    .defaultBtn {
        padding: 10px 15px;
    }

    .containerWithBtn {
        width: fit-content;
        width: 100%;
    }

    .containerTextImage {
        gap: 10px;
    }

    .littleBtn {
        font-size: .8rem !important;
        padding: 5px 10px !important;
        margin: 0 0px 0 0 !important;
        min-width: 75px;
    }

    .oneStepText {
        padding: 30px 0 30px 15px;
    }

    .stepCircle {
        max-width: 300px;
        width: 25px;
        height: 25px;
        left: -13px;
        top: 37px;
    }

    .headerStepPrice {
        display: flex;
        flex-direction: column;
    }

    .stepText {
        width: 100%;
        padding: 0 0 0 10px;
    }

    .stepCircleText {
        width: calc(100vw - 46px) !important;
        left: -4px !important;
    }

    .imageContainer {
        height: 1.2rem;
    }

    .cardContainer {
        flex-direction: column;
    }

    .imageItemContainer {
        justify-content: center;
        gap: 0.5rem;
    }

    .badge {
        height: fit-content;
    }

    .containerOneStep {
        min-width: 0;
    }
    .stepLine {
        width: 4px;
    }

    .greyDots {
        width: 5px;
        height: 5px;
    }
    .purpleDots {
        width: 5px;
        height: 5px;
    }

    .headerStep {
        flex-direction: column;
        position: sticky;
        top: 67px;
        background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(15px);
        z-index: 10000;
        gap: 1rem;
        padding: 20px 0;
        margin: 0;
    }

    .headerStepPrice {
        flex-direction: row;
        align-items: center;
        gap: .5rem;
    }

    .emptyBasket {
        flex-direction: column;
    }

    .titleStep {
        padding: 0 20px;
        max-height: 81px;
    }

    .btnBottomPage {
        flex-direction: column-reverse !important;
        align-items: center;
        gap: 1rem;
    }

    .greenDots::before {
        left: 7px;
        top: 3px;
        width: 9px;
        height: 12px;
        border-width: 0 2px 2px 0;
    }

    .littleDefaultLink {
        margin: 0;
    }
    .btnValiderPayment {
        min-width: 143.19px;
        min-height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}