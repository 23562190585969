@import url(https://fonts.googleapis.com/css2?family=Otomanopee+One&display=swap);
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    min-height: 100vh;
    overflow-x: hidden;
}

.noScroll{
    overflow-y: hidden;
}

html {
    min-height: 100%;
}

h1 {
    font-family: "Otomanopee One", Roboto,sans-serif;
    font-size: 200%; /*correspond à 32px)*/
    font-weight: normal;
}

.loader{
    min-height:400px;
    margin: 20px auto 50px;
    background-color: transparent;
    align-content: center;
}

.loader > div {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #571088;
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    -webkit-animation: l3 1s infinite linear;
            animation: l3 1s infinite linear;
    margin:auto;
}
@-webkit-keyframes l3 {to{-webkit-transform: rotate(1turn);transform: rotate(1turn)}}
@keyframes l3 {to{-webkit-transform: rotate(1turn);transform: rotate(1turn)}}


@media (max-width: 1020px) {
    h1 {
        font-size: 150%;
    }
}

h2 {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 700;
    font-size: 150%;  /*correspond à 24px*/
    /* letter-spacing: 1px; */
}

/*@media (max-width: 1020px) {*/
/*    h2 {*/
/*        font-size: 100%; !*correspond à 32px*!*/
/*    }*/
/*}*/

.Mui-selected {
    background-color: #571088 !important;
}

.Mui-error {
    color: #495057 !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #ced4da !important;
}

.Mui-focused {
    color: #571088 !important;
}

.css-1mtsuo7 {
    z-index: 100000 !important;
}

.fa-2xl {
    font-size: 2em;
    line-height: 0.03125em;
}

select {
    font-size: 16px;   
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
}
.PersonnalInformation_personalInfoContainer__lXNtb {
    display: flex;
    justify-content: center;
    align-items: center;
}


.PersonnalInformation_personalInfoCard__fpOc2 {
    background: #F7FAFC;
    padding: 20px;
    width: 80%;
    margin: 100px 100px 50px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

@media (max-width: 800px) {
    .PersonnalInformation_personalInfoCard__fpOc2 {
        margin: 100px 20px 50px;
    }
}


.PersonnalInformation_btn__1i3Bh {
    background: #571088;
    padding: 10px 25px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 1rem;
    display: block;
    margin : 10px auto;
}

.PersonnalInformation_btn__1i3Bh:hover {
    background: #4C0C78;
    cursor: pointer;
}

.PersonnalInformation_btnFemale__56gdi {
    border-radius: 25px 0 0 25px;
}

.PersonnalInformation_btnMale__3qLCR {
    border-radius: 0 25px 25px 0;
}

.PersonnalInformation_btnSelected__3MYIh {
    background: #571088;
    padding: 10px 25px;
    height: calc(2.25rem + 2px);
    color: #FFFFFF;
    border: none;
    font-size: 1rem;
    display: block;
}

.PersonnalInformation_btnNotSelected__6TkF3 {
    background: #C4C4C4;
    padding: 10px 25px;
    color: #E5E5E5;
    border: none;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    display: block;
}

.PersonnalInformation_btnFemale__56gdi:hover .PersonnalInformation_btnMale__3qLCR:hover {
    background: #4C0C78;
    cursor: pointer;
}

.PersonnalInformation_loginFooter__2S3nX {
    text-align: center;
}

.PersonnalInformation_loginFooter__2S3nX a {
    display: block;
    color: #3EA79D;
    text-decoration: none;
}

.PersonnalInformation_loginFooter__2S3nX a:hover {
    text-decoration: underline;
}



.PersonnalInformation_disabledBtn__EESQ6 {
    background-color: #C4C4C4;
    padding: 10px 25px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 1rem;
    display: block;
    margin : 10px auto;
}

button {
    cursor: pointer;
}

.Style_defaultBtn__KBzBe {
    background: #571088;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
    border: none;
    text-decoration: none;
    text-align: center;
}

.Style_defaultBtn__KBzBe:hover {
    background: #4c0c78;
    text-decoration: none;
    cursor: pointer;
    color: white;
}

.Style_secondaryBtn__1AhP7 {
    border: 1px solid #571088;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    font-weight: 500;
    padding: 9px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height: auto;
    color: #571088;
    text-decoration: none;
    background: none;
    text-align: center;
}

.Style_secondaryBtn__1AhP7:hover {
    background: #571088;
    color: white;
    border: 1px solid #571088;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 1020px) {

    .Style_defaultBtn__KBzBe,
    .Style_secondaryBtn__1AhP7 {
        font-size: 1rem;
    }
}

.Style_defaultLink__3xJB4 {
    color: #571088;
    font-weight: 500;
    background: none;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    font-size: 1rem;
    text-decoration: none;
}

.Style_defaultLink__3xJB4:hover {
    color: #4c0c78;
    text-decoration: underline;
    cursor: pointer;
}

.Style_globalContainer__3_UId {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1020px;
    margin: 10px auto 50px auto;
    min-height: 400px;
}

@media (max-width: 1020px) {
    .Style_globalContainer__3_UId {
        margin-top: 0;
        margin-right: 10px;
        margin-left: 10px;
    }
}

.Style_card__1JMi8 {
    background: #f3f6f8;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    text-wrap: balance;
}

.Style_cardMore__1wYeB {
    padding: 30px;
    flex: 1 1;
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 20%);
    border-radius: 5px;
    display: flex;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column
}


.Style_bulle__2E2hn {
    margin: 30px 20px;
    /*border: 0.1rem solid #6fa939;*/
    background: #f3f6f8;
    font-size: 90%;
    color: black;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 1fr auto;
}

.Style_bulle__2E2hn>.Style_iconBulle__QlmN2 {
    -webkit-clip-path: polygon(-1px -1px, 0 100%, 80% 100%, 100% 50%, 80% -1px);
    clip-path: polygon(-1px -1px, 0 100%, 80% 100%, 100% 50%, 80% -1px);
    background: #6fa939;
    padding: 15px 35px 15px 30px;
    border-radius: 0.2rem 0 0 0.2rem;
    font-size: 200%;
    color: white;
    display: flex;
    align-items: center;
}

.Style_bulle__2E2hn>.Style_iconText__2TV3G {
    padding: 15px;
}

.Style_badge__2drMM {
    border-radius: 4px;
    padding: 3px 10px;
    color: white;
    font-size: 16px;
    font-weight: normal;
}

.Style_childCard__M1TKd {
    flex: 1 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Style_break__baHbn {
    flex-basis: 100%;
    height: 0;
}

.Style_cardContainer__2xNue {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 960px) {}

.Style_customSelect__2JJoq {
    width: 150px;
    padding: 3px;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.25rem;

}

.Style_containerStepAndContent__3tFig {
    display: flex;
    flex-direction: row;
}

.Style_stepLine__2fWFR {
    width: 7px;
    height: 107%;
    background-color: #571088;
    position: relative;
    border-radius: 50px;
    color: #571088;
}

.Style_stepLine__2fWFR.Style_last__1AEL5{
    height: 100%;
}


.Style_greenDots__nBy-F::before {
    content: "";
    position: absolute;
    left: 12px;
    top: 8px;
    width: 12px;
    height: 16px;
    border: solid rgb(111, 169, 57);
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.Style_purpleDots__34hQb {
    width: 10px;
    height: 10px;
    background-color: #571088;
    border-radius: 50%;
    display: inline-block;
}

.Style_greyDots__2Ui_1 {
    width: 10px;
    height: 10px;
    background-color: #7a7a7a;
    border-radius: 50%;
    display: inline-block;
}

.Style_stepCircleText__30xHd {
    left: 150px !important;
    width:  calc(1020px - 52px) !important;
    margin: auto;
    border: none !important;
    left: 42px !important;
    position: absolute;
}

.Style_stepText__2xZVu {
    font-size: 100% !important;
    padding: 10px;
    width: 100%;
}

.Style_stepCircle__2NF5o {
    max-width: 993px;
    position: absolute;
    left: -18px;
    top: 43px;
    width: 40px;
    height: 40px;
    background-color: white;
    border: 2px solid #571088;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Style_stepLineGrey__wougu {
    background-color: #7a7a7a;
    height: 115px !important;
}

.Style_stepLineGreen__ss8lu {
    background-color: rgb(111, 169, 57);
    height: 115px !important;
    color: black !important;
}

.Style_stepLineGrey__wougu div {
    border: #7a7a7a 2px solid;
}

.Style_stepLineGrey__wougu div div {
    color: #7a7a7a;
    border: none;
}

.Style_stepLineGreen__ss8lu div {
    border: rgb(111, 169, 57) 2px solid;
}

.Style_stepLineGreen__ss8lu div div {
    color: rgb(111, 169, 57);
    border: none;
}

.Style_containerOneStep__mi4wf {
    margin-bottom: -5px;
    min-width: 52px;
    position: relative;
    z-index: 10;
}

.Style_titleStep__SwYtD {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1.3rem;
}

.Style_containerStepAndBasket__1pkVw {
    display: flex;
}

.Style_basketContainer__1sALV {
    margin-top: 110px;
    width: 100%;
}

.Style_oneStepText__3WYua {
    padding: 30px 0 30px 30px;
}

/* image cb et livraison */

.Style_imageItemContainer__2TQ6J {
    display: flex;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    align-items: center;
}

.Style_imageContainer__2ynvM {
    aspect-ratio: 16/9;
    border-radius: 0.3rem;
    overflow: hidden;
    height: 1.8rem;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Style_imageContainer__2ynvM img {
    object-fit: contain;
    height: 80%;
    width: 80%;
}

.Style_buttonNextStep__1nZGr {
    align-self: flex-end;
}

.Style_containerWithBtn__3lU74 {
    justify-content: space-between;
}

.Style_containerTextImage__3qvnC {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
}

.Style_headerStep__2WAP2 {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    align-items: center;
    max-width: 1020px;
    margin: 30px auto 10px;
}

.Style_headerStepPrice__yLVkQ {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
}

.Style_littleBtn__2JoUR {
    padding: 5px 20px !important;
    font-size: 1rem !important;
    min-width: 111.89px;
    min-height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Style_headernbArticlePrice__2SH3r {
    display: flex;
    flex-direction: column;
    position: relative;
    grid-gap: .5rem;
    gap: .5rem;
}

.Style_btnBottomPage__1Sbtd {
    margin-top: 30px;
}

.Style_greyImage__cg-IN {
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
}

.Style_containerImage__JUQgC {
    display: flex;
    align-items: center;
}

.Style_contenuImage__1NLd6 {
    width: auto;
    height: 40px;
    object-fit: cover;
}

.Style_singleItem__3Ebfk {
    display: flex;
    align-items: center;
    grid-gap: .5rem;
    gap: .5rem;
}

.Style_articleName__245d2 {
    color: black;
    font-size: .8rem;
    font-weight: normal;
}

.Style_articleQty__10ns6{
    position:absolute;
    top:-10px;
    right:-10px;
    background-color: white;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    font-size: .85rem;
    padding: 1px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 4px 0px;
}

.Style_selectPointBtn__1UDV8 {
    padding: 6px 20px;
    background: #571088;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 70px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
    text-decoration: none;
    text-align: center;
    border: none;
    cursor: pointer;
}

.Style_selectPointBtn__1UDV8:hover {
    background: #4c0c78;
}

.Style_containerTitleIcon__1GEFF {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    min-width: 395px;
}

.Style_tooltipContainer__2TQFA {
    position: relative;
}

.Style_emptyBasket__29Eqe {
    display: flex;
    justify-content: center;
    min-height: 400px;
    margin: 20px auto 50px;
    align-content: center;
    align-items: center;
}

.Style_emptyBasket__29Eqe>div {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    text-align: center;
}

.Style_emptyBasket__29Eqe>div>a {
    margin-top: 20px;
}

.Style_emptyBasket__29Eqe>div>img {
    height: 320px;
}

.Style_containerBtnError__w2B9T {
    position: relative;
}

.Style_arianeSkeleton__3zdjD {
    background: linear-gradient(90deg, rgba(100, 100, 100, 0.1) 0%, #fff 20%, rgba(100, 100, 100, 0.1) 40%);
    animation: Style_leftToRight__MssMT 1s infinite reverse;
    background-size: 200%;
    min-height: 20px;
    width: 200px !important;
    border-radius: 15px;
}

h1.Style_whiteSkeleton__3vH91 {
    background: linear-gradient(90deg, rgba(255, 255, 255, .5) 0%, rgba(255, 255, 255, .7) 50%, rgba(255, 255, 255, .5) 100%);
    animation: Style_leftToRight__MssMT 1s infinite reverse;
    background-size: 200%;
    border-radius: 25px;
    min-height: 30px;
    width: 250px;
    margin-top: 31px !important;
}

@-webkit-keyframes Style_leftToRight__MssMT {
    0% {
        background-position: -100% 0;
    }

    100% {
        background-position: 100% 0;
    }
}

@keyframes Style_leftToRight__MssMT {
    0% {
        background-position: -100% 0;
    }

    100% {
        background-position: 100% 0;
    }
}

.Style_loaderNextStep__1uEpZ {
    width: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    -webkit-animation: Style_l5__2qWTK 1s infinite linear alternate;
            animation: Style_l5__2qWTK 1s infinite linear alternate;
    flex: none !important;
    cursor: default;
}

.Style_littleLoaderNextStep__Ypj7J {
    width: 6px;
    aspect-ratio: 1;
    border-radius: 50%;
    -webkit-animation: Style_l5Little__1p3DY 1s infinite linear alternate;
            animation: Style_l5Little__1p3DY 1s infinite linear alternate;
    flex: none !important;
    cursor: default;
}

.Style_btnNextStep__3VQ7t {
    display: flex;
    min-width: 237.66px;
    min-height: 46px;
    justify-content: center;
    align-items: center;
}

@-webkit-keyframes Style_l5__2qWTK {
    0% {
        box-shadow: 20px 0 white, -20px 0 transparent;
        background: white
    }

    33% {
        box-shadow: 20px 0 white, -20px 0 transparent;
        background: transparent
    }

    66% {
        box-shadow: 20px 0 transparent, -20px 0 white;
        background: transparent
    }

    100% {
        box-shadow: 20px 0 transparent, -20px 0 white;
        background: white
    }
}

@keyframes Style_l5__2qWTK {
    0% {
        box-shadow: 20px 0 white, -20px 0 transparent;
        background: white
    }

    33% {
        box-shadow: 20px 0 white, -20px 0 transparent;
        background: transparent
    }

    66% {
        box-shadow: 20px 0 transparent, -20px 0 white;
        background: transparent
    }

    100% {
        box-shadow: 20px 0 transparent, -20px 0 white;
        background: white
    }
}

@-webkit-keyframes Style_l5Little__1p3DY {
    0% {
        box-shadow: 10px 0 white, -10px 0 transparent;
        background: white
    }

    33% {
        box-shadow: 10px 0 white, -10px 0 transparent;
        background: transparent
    }

    66% {
        box-shadow: 10px 0 transparent, -10px 0 white;
        background: transparent
    }

    100% {
        box-shadow: 10px 0 transparent, -10px 0 white;
        background: white
    }
}

@keyframes Style_l5Little__1p3DY {
    0% {
        box-shadow: 10px 0 white, -10px 0 transparent;
        background: white
    }

    33% {
        box-shadow: 10px 0 white, -10px 0 transparent;
        background: transparent
    }

    66% {
        box-shadow: 10px 0 transparent, -10px 0 white;
        background: transparent
    }

    100% {
        box-shadow: 10px 0 transparent, -10px 0 white;
        background: white
    }
}

.Style_nextStepText__eyA60 {
    color: white !important;
}

.Style_littleDefaultLink__3tO_h {
    margin: 0 3rem 0 0;
}

.Style_btnValiderPayment__eco4- {
    min-width: 173.19px;
}

@media screen and (max-width: 768px) {

    .Style_containerTitleIcon__1GEFF {
        min-width: 175px;
    }

    .Style_titleStep__SwYtD {
        font-size: 1rem;
    }

    .Style_defaultBtn__KBzBe {
        padding: 10px 15px;
    }

    .Style_containerWithBtn__3lU74 {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        width: 100%;
    }

    .Style_containerTextImage__3qvnC {
        grid-gap: 10px;
        gap: 10px;
    }

    .Style_littleBtn__2JoUR {
        font-size: .8rem !important;
        padding: 5px 10px !important;
        margin: 0 0px 0 0 !important;
        min-width: 75px;
    }

    .Style_oneStepText__3WYua {
        padding: 30px 0 30px 15px;
    }

    .Style_stepCircle__2NF5o {
        max-width: 300px;
        width: 25px;
        height: 25px;
        left: -13px;
        top: 37px;
    }

    .Style_headerStepPrice__yLVkQ {
        display: flex;
        flex-direction: column;
    }

    .Style_stepText__2xZVu {
        width: 100%;
        padding: 0 0 0 10px;
    }

    .Style_stepCircleText__30xHd {
        width: calc(100vw - 46px) !important;
        left: -4px !important;
    }

    .Style_imageContainer__2ynvM {
        height: 1.2rem;
    }

    .Style_cardContainer__2xNue {
        flex-direction: column;
    }

    .Style_imageItemContainer__2TQ6J {
        justify-content: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }

    .Style_badge__2drMM {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .Style_containerOneStep__mi4wf {
        min-width: 0;
    }
    .Style_stepLine__2fWFR {
        width: 4px;
    }

    .Style_greyDots__2Ui_1 {
        width: 5px;
        height: 5px;
    }
    .Style_purpleDots__34hQb {
        width: 5px;
        height: 5px;
    }

    .Style_headerStep__2WAP2 {
        flex-direction: column;
        position: sticky;
        top: 67px;
        background-color: rgba(255, 255, 255, 0.7);
        -webkit-backdrop-filter: blur(15px);
                backdrop-filter: blur(15px);
        z-index: 10000;
        grid-gap: 1rem;
        gap: 1rem;
        padding: 20px 0;
        margin: 0;
    }

    .Style_headerStepPrice__yLVkQ {
        flex-direction: row;
        align-items: center;
        grid-gap: .5rem;
        gap: .5rem;
    }

    .Style_emptyBasket__29Eqe {
        flex-direction: column;
    }

    .Style_titleStep__SwYtD {
        padding: 0 20px;
        max-height: 81px;
    }

    .Style_btnBottomPage__1Sbtd {
        flex-direction: column-reverse !important;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
    }

    .Style_greenDots__nBy-F::before {
        left: 7px;
        top: 3px;
        width: 9px;
        height: 12px;
        border-width: 0 2px 2px 0;
    }

    .Style_littleDefaultLink__3tO_h {
        margin: 0;
    }
    .Style_btnValiderPayment__eco4- {
        min-width: 143.19px;
        min-height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}
.FormStyle_inputLine__Wq5BM{
    display: flex;
}

@media (max-width: 1000px) {
    .FormStyle_inputLine__Wq5BM {
        flex-direction: column;
    }
}

.FormStyle_inputGroup__2xUoj {
    flex: 1 1;
    margin: 10px 20px;
}

.FormStyle_input__2P7km {
    display: inline-block;
    flex: 12 1;
    box-sizing: border-box;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    background-color: white !important;
}

input[type="radio"], label{
    cursor:pointer;
}

input[type="radio"]{
    accent-color: #571088;
}

@media (max-width: 800px) {
    .FormStyle_input__2P7km {
        flex: 7 1;
    }
}

.FormStyle_input__2P7km:focus {
    outline: none;
}

.FormStyle_inputIcon__5vCjT {
    flex: 1 1;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

.FormStyle_inputField__3SkJm {
    flex: 1 1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    height: calc(2.25rem + 5px);
    margin-top: 5px;
}

.FormStyle_eyeIcon__3b7Lc {
    flex: 1 1;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    color: #495057;
}

.FormStyle_iconHelp__Qfm_2 {
    margin-left: 5px;
    color: #888;
}


.FormStyle_inputGroupBirthGender__1f7bF{
    flex: 1 1;
    display: flex;
    flex-direction: row;
    margin: auto 20px;
}

.FormStyle_gender__2HYUl button{
    font-size: 1.15rem;
}

@media (max-width: 800px) {
    .FormStyle_inputGroupBirthGender__1f7bF {
        flex-direction: column;
    }
}


.FormStyle_inputGroupBirth__24XoN {
    flex: 3 1;
    margin: 10px 0 10px 20px;
}

@media (max-width: 800px) {
    .FormStyle_inputGroupBirth__24XoN {
        margin-left: 0;
    }
}



.FormStyle_inputFieldBirth__z_6wD {
    box-sizing: border-box;
    position: relative;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    margin: 5px 0 0;
    height: calc(2.25rem + 5px);
}

.FormStyle_inputContent__3iMzr{
    display: flex;
    flex: 1 1;
    margin: 0;
    height: calc(2.25rem + 5px);
    justify-content: center;
    align-items: center;
}

.FormStyle_inputDateContainer__Orc5b {
    flex: 12 1;
}

.FormStyle_inputDate__3oZgY {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    background-color: white !important;
}

.FormStyle_inputDate__3oZgY:focus{
    outline: none;
}

.FormStyle_textDanger__3mrML{
    color: red;
    margin-left: 20px;
    margin-top: -10px;
}

.FormStyle_disabledBtn__3wWcf {
    background: #C4C4C4;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height:auto;
    color: white;
    border: none;
    text-decoration: none;
    cursor: default !important;
}

.FormStyle_defaultBtn__3PqrV:hover {
    background: #C4C4C4;
    text-decoration: none;
    color: white;
}

.FormStyle_inputLineSame__1O5sX{
    display: flex;
}

.FormStyle_checkbox__1yoWl {
    margin: 0 25px;
    height: 15px;
    width: 15px;
    border: #1C4CBD !important;
    background-color: #1C4CBD !important;
}

input[type=checkbox] {
    cursor: pointer;
    visibility: hidden;
    height: 15px;
    width: 15px;
    -moz-appearance:initial;
}

input[type=checkbox]::after {
    content: " ";
    background-color: #fff;
    color: #571088;
    visibility: visible;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

input[type=checkbox]:checked:after {
    content: "\2714";
    font-weight: bold;
}

/** Boutons pour choisir le sexe **/

.FormStyle_inputGroupGender__3pvTr {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.FormStyle_gender__2HYUl {
    flex: 1 1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    height: calc(2.25rem + 5px);
    justify-content: flex-start;
    align-items: center;
    margin: 0;
}

.FormStyle_btnSelected__11HxI {
    background: #571088;
    padding: 10px 25px;
    height: calc(2.25rem + 2px);
    color: #FFFFFF;
    border: none;
    font-size: 1rem;
    display: block;
}

.FormStyle_btnNotSelected__238gr {
    background: #C4C4C4;
    padding: 10px 25px;
    color: #E5E5E5;
    border: none;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    display: block;
}

.FormStyle_btnFemale__3mzGi{
    border-radius: 25px 0 0 25px;
}

.FormStyle_btnMale__3nLwO{
    border-radius: 0 25px 25px 0;
}

.FormStyle_btnFemale__3mzGi:hover .FormStyle_btnMale__3nLwO:hover {
    background: #4C0C78;
    cursor: pointer;
}


/** Bouton à la fin du formulaire **/

.FormStyle_btnContainer__3zb7u {
    display: flex;
    justify-content: center;
    align-items: center
}

.FilAriane_mainContainer__2dTOI {
    width: 100%;
    background: #f3f6f8;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center
}

/*@media (max-width: 1020px) {*/
/*    .mainContainer {*/
/*        display: none;*/
/*    }*/
/*}*/

.FilAriane_contentContainer__eIU_T {
    width: 1020px;
    margin: 5px 15px 15px;
}

@media (max-width: 1020px) {
    .FilAriane_contentContainer__eIU_T {
        width: calc(100% - 30px);
        flex-wrap: nowrap;
        overflow-x: auto;
        display: flex;
        white-space: nowrap;
        padding: 0 20px 0 0;
        grid-gap: 7px;
        gap: 7px;
    }

    .FilAriane_contentContainer__eIU_T::-webkit-scrollbar {
        display: none;
    }
}
.Shipping_shippingContainer__3u9eZ {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 50px;
}

.Shipping_previousContainer__yevRX {
    display: flex;
    width: 80%;
    align-items: flex-start;
    padding: 20px;
}

.Shipping_previousBtn__nUTnv {
    cursor: pointer;
    position: relative;
    color: #555;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    background-color: transparent;
    border: none;
}

.Shipping_previousIcon__2NQZa {
    margin-right: 5px;
}


.Shipping_shippingCard__23brB {
    background: #F7FAFC;
    padding: 20px;
    width: 80%;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}


.Shipping_inputLine__3rq47{
    display: flex;
}

@media (max-width: 1000px) {
    .Shipping_inputLine__3rq47 {
        flex-direction: column;
    }
}

.Shipping_inputLineSame__2LyGU{
    display: flex;
}

.Shipping_inputContent__3F5mI{
    display: flex;
    flex: 1 1;
    margin: 20px;
    height: calc(2.25rem + 5px);
    justify-content: center;
    align-items: center;
}

.Shipping_inputDateContainer__1ktoc {
    flex: 12 1;
}

.Shipping_inputDate__j0Jvm {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    background-color: white !important;
}

.Shipping_textDanger__3lKbt{
    color: red;
    margin-left: 20px;
    margin-top: -10px;
}

.Shipping_inputDate__j0Jvm:focus{
    outline: none;
}

.Shipping_inputField__3OS7S {
    flex: 1 1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    height: calc(2.25rem + 5px);
    margin-top: 5px;
}

.Shipping_inputGroup__2lIm4{
    flex: 1 1;
    margin: 10px 20px;
}

.Shipping_input__3ntU_ {
    display: inline-block;
    flex: 12 1;
    box-sizing: border-box;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    background-color: white !important;
}

@media (max-width: 800px) {
    .Shipping_input__3ntU_ {
        flex: 7 1 !important;
    }
}

.Shipping_input__3ntU_:focus {
    outline: none;
}

.Shipping_inputIcon__3U3D9 {
    flex: 1 1;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Shipping_btn__XS0dx {
    background: #51276C;
    padding: 10px 25px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 1rem;
    display: block;
    margin: 20px auto 10px;
}

.Shipping_btn__XS0dx:hover {
    background: #4C0C78;
    cursor: pointer;
}

.Shipping_disabledBtn__CMEcM {
    background-color: #C4C4C4;
    padding: 10px 25px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 1rem;
    display: block;
    margin : 10px auto;
}

.Shipping_btnFemale__3osBB {
    border-radius: 25px 0 0 25px;
}

.Shipping_btnMale__eMiGc {
    border-radius: 0 25px 25px 0;
}

.Shipping_btnSelected__1ePO5 {
    background: #51276C;
    padding: 10px 25px;
    height: calc(2.25rem + 2px);
    color: #FFFFFF;
    border: none;
    font-size: 1rem;
    display: block;
}

.Shipping_btnNotSelected__YwJXE {
    background: #C4C4C4;
    padding: 10px 25px;
    color: #E5E5E5;
    border: none;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    display: block;
}




.Empty_empty__2zh-P{
    position: absolute;
    right: 0;
    left: 0;
    bottom: 40px;
}

.Empty_div_100__16I8d {
    min-height: 100%;
}

.Header_navbar__1yqdk {
  background: #ffffff;
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 10006;
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
}

/* Logo dans la navbar */

.Header_logoContainer__3AxL9 {
  text-align: left;
  display: inline-block;
  height: 50px;
  padding: 15px 60px 15px 20px;
}

@media screen and (max-width: 960px) {
  .Header_logoContainer__3AxL9 {
    padding: 15px 20px 19px 20px;
    flex: 1 1;
    height: 45px;
  }

  
}

.Header_logo__2bDNf {
  aspect-ratio: 500/160;
  height: 80%;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .Header_logo__2bDNf {
    width: 100%;
    object-fit: contain;
    object-position: left;
    height: 45px;
  }
}

/* Liens dans la navbar */

.Header_link__2uOHK {
  color: #000;
  font-size: 1.1rem;
  /* height: 75px; */
  padding: 0 24px;
  text-decoration: none;
  grid-gap: 16px;
  gap: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  font-weight: 500;
}

.Header_link__2uOHK:hover {
  text-decoration: none;
  cursor: pointer;
}

.Header_btn__3tlto {
  padding: 10px 20px !important;
  font-size: 1.1rem !important;
}

.Header_redBtn__2z-v5 {
  background-color: #ce3534;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  font-weight: 500;
  border-radius: 70px;
  font-size: 1.2rem;
  height: auto;
  color: white;
  margin-left: 15px;
  text-decoration: none;
}

.Header_redBtn__2z-v5:hover {
  text-decoration: none;
  background-color: #c02727;
  color: white;
}

.Header_leftBtn__upvyk {
  right: 20px;
  position: absolute;
}

.Header_mobileUserBtn__3qoAG {
  height: 70% !important;
  border: none;
  background: none;
  color: #571088;
  width: auto;
  padding: 7px 5px 3px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .Header_mobileUserBtn__3qoAG {
    display: flex;
    position: relative;
    padding: 7px 6px 3px 0 !important;
  }

  .Header_mobileUserBtnMarginLeft__2d_3l {
    margin-left: 2px;
    margin-bottom: 2px;
  }

  .Header_leftBtnContainer__1p5P- {
    flex-direction: row-reverse !important;
    justify-content: flex-start !important;
  }
}

.Header_mobileUserBtn__3qoAG:hover {
  color: #4c0c78;
}

.Header_leftBtnContainer__1p5P- {
  right: 20px;
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.Header_profileBtn__1iehk {
  display: grid;
  grid-template-columns: 34px 0fr;
  transition: grid-template-columns 0.5s ease-out;
  transition: grid-template-columns 0.5s ease-out, -ms-grid-columns 0.5s ease-out;
  align-items: center;
  background: #e6e6e8;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  font-weight: 500;
  padding: 4px 4px 4px 4px;
  border-radius: 70px;
  font-size: 1rem;
  height: 36px;
  color: white;
  border: none;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  margin-left: auto;
  cursor: pointer;
  overflow: hidden;
}

.Header_profileBtn__1iehk span {
  transition: padding 0.3s, opacity 0.3s;
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  font-weight: 500;
  width: auto;
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
}

.Header_profileBtn__1iehk:hover {
  grid-template-columns: 34px 1fr;
}

.Header_profileBtn__1iehk:hover span {
  padding-right: 10px;
  padding-left: 0.5rem;
  opacity: 1;
}

.Header_iconUser__viZ1R {
  background-color: #571088;
  border-radius: 50px;
  padding: 10px;
}

.Header_iconLoad__2x0xQ {
  -webkit-animation: Header_iload__32MHf 1s infinite linear;
          animation: Header_iload__32MHf 1s infinite linear;
  margin: auto;
}

@-webkit-keyframes Header_iload__32MHf {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@keyframes Header_iload__32MHf {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@media screen and (max-width: 960px) {
  .Header_leftBtnContainer__1p5P- {
    position: relative;
    flex: 1 1;
    margin-right: 17px;
    right: 0;
    grid-gap: 1.3rem;
    gap: 1.3rem;
    margin-left: 1px;
  }
}

.Header_menu__irMJU {
  font-size: 1.8rem !important;
  border: none;
  background: none;
}

@media screen and (max-width: 960px) {
  .Header_menu__irMJU {
    padding: 1px 6px;
    margin-top: 1px;
    width: 35px;
  }
}

.Header_dropdownMenu__1rl7f {
  z-index: 10005;
  background: white;
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 10%);
}

.Header_mobileLink__1GXYa {
  color: #000000;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  text-decoration: none;
}

.Header_mobileBtn__Wz0Zu {
  padding: 10px 20px !important;
  font-size: 1.1rem !important;
}

.Header_mobileRedBtn__BUHC9 {
  background-color: #ce3534;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  font-weight: 500;
  border-radius: 70px;
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
}

.Header_mobileMenuItem__3l4TT {
  border-style: solid;
  border-color: rgba(132, 132, 132, 0.15);
  border-width: 0;
  border-bottom-width: 1px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}

.Header_dropdownMenuConnected__olf6I {
  z-index: 10010;
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  text-align: right;
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 10%);
  right: 0;
  border-top: 1px solid #ddd;
}

.Header_mobileDropdownMenuConnected__3r2fD {
  z-index: 10005;
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 10%);
  width: 100%;
}

.Header_basketShopping__2qx1g {
  background-image: url(/static/media/basket-shopping.ef6c8825.svg);
  width: 37px;
  height: 37px;
  background-repeat: no-repeat;
}

.Header_dialogContainer__vs2As {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Fond semi-transparent pour assombrir l'arrière-plan */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10009;
}

.Header_radioBtn__3smpE {
  align-self: center;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.Header_checkboxLabel__1ZplW {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  justify-content: center;
}

.Header_customCheckbox__3mNnV {
  width: 50px;
  height: 50px;
  /* background-color: white; */
  border: 3px solid #e4e4e4;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s ease;
  top: -3px;
  left: -3px;
  border-radius: 10px 0;
}

.Header_radioBtn__3smpE:checked + .Header_checkboxLabel__1ZplW .Header_customCheckbox__3mNnV::after {
  content: "";
  position: absolute;
  top: 8px;
  left: 17px;
  width: 13px;
  height: 19px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.Header_customCheckbox__3mNnV:not(:checked) {
  background-color: #e4e4e4;
}

.Header_radioBtn__3smpE:not(:checked) + .Header_checkboxLabel__1ZplW .Header_customCheckbox__3mNnV::after {
  content: "";
  position: absolute;
  top: 11px;
  left: 13px;
  width: 17px;
  height: 17px;
  background-color: white;
  border-radius: 50px;
  border: solid #e3e3e3;
  /* border-width: 0 3px 3px 0; */
  /* transform: rotate(45deg); */
}

/* Change le background color de la case cochée */
.Header_radioBtn__3smpE:checked + .Header_checkboxLabel__1ZplW .Header_customCheckbox__3mNnV {
  border-color: #6fa939;
  background-color: #6fa939;
}

.Header_containerPrix__k4POV {
  margin-right: 30px;
  display: flex;
  align-items: flex-end;
}

.Header_ContainerPanier__1Azyc {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  /* background-color: #f7fafc; */
  border-radius: 15px;
  border: 3px solid #e4e4e4;
  flex: 1 1;
  cursor: pointer;
}

.Header_titleDialogBox__254In {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
  font-weight: 700;
}

.Header_dialogBox__1baAX {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Ombre légère */
  z-index: 10010;
  position: fixed;
  top: 100px;
  /* overflow-y: scroll; */
}

.Header_dialogBox__1baAX > div {
  margin: 10px 10px 25px 10px;
  font-size: 1.2rem;
}

.Header_containerChoice__16NHk {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
}

.Header_containerPrixArticle__3sSGK {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
}

.Header_containerResumeBasket__2ARTo {
  display: flex;
}

.Header_whiteShadow__CBqSt {
  -webkit-mask: linear-gradient(180deg, #0000, #000 0% 50%, #0000);
          mask: linear-gradient(180deg, #0000, #000 0% 50%, #0000);
}

.Header_containerOneChoice__2Sk5S {
  position: relative;
  overflow-y: hidden;
  transition: height 1s ease-in-out;
  max-height: 300px;
  overflow-y: scroll;
}

.Header_containerOneChoice__2Sk5S::-webkit-scrollbar {
  display: none;
}

.Header_buttonPanier__373G7 {
  background-color: transparent;
  border: 1px solid transparent;
  align-self: flex-start;
  border-radius: 10px;
  display: flex;
  width: 100%;
  height: 3rem;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0 0;
  margin: 7px 0;
}
.Header_containerquestion__3tegg {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.Header_buttonChoice__2dQUf {
  position: absolute;
  top: 28px;
  right: 32px;
  font-family: "Segoe UI SemiBold", sans-serif;
  border-radius: 50px;
  color: white;
  padding: 10px 20px;
  font-weight: 700;
  border: none;
  background-color: #571088;
  /* bottom: -29px; */
  cursor: pointer;
  font-size: 1rem;
}

.Header_buttonChoice__2dQUf:hover {
  background-color: #4c0c78;
}

.Header_arrow__1XhjN {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  font-size: 1rem;
}


/* GESTION AFFICHAGE HOVER ABSKET */

.Header_overlayBasket__pNvbI {
  z-index: -1;
}


.Header_overlayBasket__pNvbI.visible {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  inset: 0;
  z-index: 100001;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
}

.Header_basketArticle__2l7v3 {
  display: none;
  position: fixed;
  top: 75px;
  left: auto;
  right: 0;
  z-index: 1000000;
  min-width: 350px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  visibility: hidden;
  /* height: 0; */
  opacity: 0;
  display: flex;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto";
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  flex-direction: column;
  padding: 1rem;
  margin: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: visibility 0.3s, opacity 0.3s linear;
}

.Header_basketArticle__2l7v3.visible {
  opacity: 1 !important;
  visibility: visible !important;
}

.Header_basketArticle__2l7v3.emptyBasket{
  padding: 0 1rem !important;
  height: 250px;
  overflow: hidden;
}

.Header_basketArticle__2l7v3.emptyBasket>div{
  font-size: 1.1rem;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  text-align: right;
  padding: 5rem 1.5rem 0 0;
}

.Header_basketArticle__2l7v3.emptyBasket>span{
  font-size: 1rem;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  text-align: right;
  padding: .5rem 1.5rem 0 0;
}

.Header_basketArticle__2l7v3.emptyBasket img{
  position: absolute;
  left: -50px;
  top: -0px;
  width: 300px;
}

.Header_loader__3_ceN{
  min-height:50px;
  margin: 20px auto;
  background-color: transparent;
  align-content: center;
}

.Header_loader__3_ceN > div {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #571088;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  -webkit-animation: Header_l3__1bFyt 1s infinite linear;
          animation: Header_l3__1bFyt 1s infinite linear;
  margin:auto;
}
@-webkit-keyframes Header_l3__1bFyt {to{-webkit-transform: rotate(1turn);transform: rotate(1turn)}}
@keyframes Header_l3__1bFyt {to{-webkit-transform: rotate(1turn);transform: rotate(1turn)}}


.Header_containerArticle__1bCyU {
  max-height: 317px;
  overflow-y: auto;
  scrollbar-width: none;
  -webkit-mask: linear-gradient(180deg,#000,#000 80%,#0000);
          mask: linear-gradient(180deg,#000,#000 80%,#0000);
}

.Header_containerArticle__1bCyU::-webkit-scrollbar {
  display: none;
}

.Header_containerItemBasket__oSYRV {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin:.5rem 0 1.5rem ;
}

.Header_imgBasket__3lkXM {
  width: 125px;
  flex: 1 1;
  object-fit: contain;
}

.Header_containerNomQty__1Bxcz {
  display: flex;
  flex: 3 1;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 2rem 0 .5rem;
  color: #000;
  max-width: 200px;
}

.Header_containerNomQty__1Bxcz select {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  font-family: Arial;
}

.Header_containerNomQty__1Bxcz a {
    color: #571088;
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto";
    font-weight: 500;
}

.Header_TrashCan__1gem- {
  cursor: pointer;
}

.Header_containerPrixBasket__r8jNO {
  display: flex;
  color: #7a7a7a;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}

.Header_containerPrixBasket__r8jNO span {
  color: black;
}

.Header_containerPrixBasket__r8jNO svg {
 opacity: .4;
}

.Header_containerButton__36O5Q {
  margin-top: 1rem;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: center;
}

.Header_containerButton__36O5Q a:first-child {
  font-weight: 500;
  border: 1px solid #571088;
  color: #571088;
  font-size: 1rem;
  background-color: transparent;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  box-sizing: content-box;
  padding-block: 0;
  padding-inline: 0;
  cursor: pointer;
  z-index: 2;
  height: 2rem;
  padding: .2rem 0.9rem;
  position: relative;
  text-decoration: none;
}

.Header_containerButton__36O5Q a:last-child {
  border: 1px solid #571088;
  color: white;
  font-size: 1rem;
  background-color: #571088;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  box-sizing: content-box;
  padding-block: 0;
  padding-inline: 0;
  cursor: pointer;
  z-index: 2;
  height: 2rem;
  padding: .2rem 0.9rem;
  position: relative;
  text-decoration: none;
  font-weight: 500;
}

.Header_containerButton__36O5Q a:hover:first-child {
  background-color: #571088;
  color: white;
}

.Header_containerButton__36O5Q a:hover:last-child{
  background-color: white !important;
  color: #571088 !important;
}

.Header_selectQuantite__3CtxU {
  cursor: pointer;
  margin: 1rem 0;
  padding: .2rem .4rem;
  border-color: #e6e6e8;
  border-radius: 7px;
  font-size: 13.3px;
}

.Header_price__ySABP {
  padding: 0.6rem 1rem;
  display: flex;
  color: black;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto";
  align-items: flex-end;
}

.Header_euro__2yKIV {
  font-size: 1.7rem;
}

.Header_centimePrice__29b8D {
  margin-bottom: 4px;
  font-size: 1rem;
}

.Header_centime__1UOUq {
  margin-bottom: 3px;
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .Header_containerChoice__16NHk {
    flex-direction: column;
  }

  .Header_buttonChoice__2dQUf {
    top: auto;
    bottom: 32px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: static;
    position: initial;
    z-index: 100;
    order: 3;
  }

  .Header_dialogBox__1baAX {
    overflow-y: scroll;
    position: fixed;
    bottom: 0;
    top: 260px;
    border-radius: 20px 20px 0 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    transition: all 1s ease-in-out;
  }

  .Header_dialogBox__1baAX > div {
    font-size: 1rem;
  }

  .Header_containerPrixArticle__3sSGK {
    font-size: 1rem;
    margin: 0 10px 0 40px;
  }

  .Header_containerPrixArticle__3sSGK > div:first-child {
    margin-right: 50px !important;
  }
  .Header_checkboxLabel__1ZplW {
    position: absolute;
    top: 17px;
    left: 10px;
  }

  .Header_centime__1UOUq {
    margin-bottom: 2px;
    font-size: 0.7rem;
  }

  .Header_radioBtn__3smpE:checked + .Header_checkboxLabel__1ZplW .Header_customCheckbox__3mNnV::after {
    top: 2px;
    left: 7px;
    width: 7px;
    height: 10px;
  }

  .Header_radioBtn__3smpE:not(:checked) + .Header_checkboxLabel__1ZplW .Header_customCheckbox__3mNnV::after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
  }
  .Header_containerOneChoice__2Sk5S {
    height: 0;
  }

  .Header_ContainerPanier__1Azyc {
    padding: 0.5rem;
    grid-gap: 0rem;
    gap: 0rem;
    border-width: 3px !important;
    position: relative;
  }

  .Header_dialogBox__1baAX > div {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .Header_containerChoice__16NHk {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .Header_customCheckbox__3mNnV {
    width: 25px;
    height: 25px;
    top: -17px;
    left: -10px;
  }

  .Header_buttonPanier__373G7 {
    align-items: flex-start;
    height: auto;
    display: flex;
    align-items: center;
  }

  .Header_mobileMenuItem__3l4TT {
    min-width: none;
  }

  /* GESTION HOVER BASKET MOBILE */

  .Header_basketArticle__2l7v3 {
    top: auto;
    bottom: 0;
    left: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    position: fixed;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    padding: 1rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: calc(100% - 2rem);
    border-radius: 12px 12px 0 0;
    visibility: visible;
    opacity: 1;
    margin: 0px;
    min-width: auto;
}

.Header_basketArticle__2l7v3.visible {
    margin: 0;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
}

.Header_containerItemBasket__oSYRV {
  align-items: flex-start;
}


.Header_imgBasket__3lkXM {
  width: 100px;
  margin-right: 10px;
}

.Header_containerPrixBasket__r8jNO>span>span {
  font-size: 1.1rem;
}

.Header_containerButton__36O5Q {
  grid-gap: 1rem;
  gap: 1rem;
}

.Header_leftBtnContainer__1p5P-{
  grid-gap:.45rem;
  gap:.45rem;
}

.Header_menu__irMJU .svg-inline--fa{
  height: 0.7em;
}

.Header_logoContainer__3AxL9{
  padding: 18px 20px 14px;
}

.Header_logo__2bDNf{
  height: 36px;
}

.Header_iconUser__viZ1R{
  padding: 6px;
}

.Header_basketShopping__2qx1g {
  height: 28px;
  width: 28px;
}

.Header_profileBtn__1iehk {
  background: transparent;
  position: relative;
  left: 6px;
}

}

.ItemBasketChoice_containerBasket__2g8uM {
    padding: 0px 30px 0px 0px;
    border-radius: 5px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    flex: 2 1;
    margin-bottom: 20px;
}

.ItemBasketChoice_price__18aHH {
    font-size: 1.5rem;
    font-weight: 600;
}

.ItemBasketChoice_productName__3nWaJ {
    margin: "0";
    text-align: left;
    font-weight: 500;
    font-size: 1.1rem;
    text-align: left;
}

.ItemBasketChoice_lastItem__3e3aC {
    margin-bottom: 80px;
}

@media screen and (max-width: 768px) {

    .ItemBasketChoice_containerBasket__2g8uM {
        padding: 0;
        border-top: 1px solid #E6E6E6;
        padding-top: 5px;
        margin-bottom: 5px;
    }

    .ItemBasketChoice_containerTitreQty__j-oFK {
        align-items: flex-start;
    }

    .ItemBasketChoice_price__18aHH {
        margin: 0 auto;
        font-size: 1rem !important;
    }

    .ItemBasketChoice_productName__3nWaJ {
        font-size: .8rem;
    }
}
.Footer_title__3B4M5 {
    color: white;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.2em;
    margin-bottom: 20px
}

.Footer_item__1oR7n {
    padding-left: 6px;
    padding-bottom: 5px;
    font-size: 1.1rem;
    margin: 5px auto;
    color: #DDDDDD;
    text-decoration: none;
}

.Footer_link__K4daH {
    color: white;
    text-decoration: none;
}

.Footer_itemLegal__1mvcy {
    margin-left: 10px;
    margin-right: 10px;
}

.Footer_linkLegal___DMZ8 {
    color: white;
    text-decoration: none;
}

.Footer_icon__1clpu {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    font-size: 20px
}

.Footer_footer__3i3JL {
    padding: 10px;
    color: white;
    font-size: 0.85rem;
    flex: 1 1;
}


.Footer_topFooter__2prFy {
    background-color: #f7fafc;
    margin-top: 4rem;
}

.Footer_topFooter__2prFy>div {
    display: flex;
    width: 1140px;
    margin: auto;
    padding: 2rem 0;
}


.Footer_topFooter__2prFy>div>div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: var(--var-font-family-semi-bold);
}

.Footer_topFooter__2prFy>div>div>div {
    display: flex;
    align-items: flex-end;
    font-weight: 500;
}

.Footer_france__1u5tf {
    aspect-ratio: 1;
    background-size: contain;
    width: 70px;
    background-repeat: no-repeat;
    background-position-y: bottom;
}

.Footer_payAndDelivery__1dOVE {
    color: white;
    display: flex;
    margin: 1rem 10px;
    font-size: 1.3rem;
    font-family: var(--var-font-family-semi-bold);
}

.Footer_logoContainer__3W5a8 {
    display: flex;
    width: 1140px;
}

.Footer_logoContainer__3W5a8>img {
    margin-left: 10px;
}

.Footer_logoContainer__3W5a8 img {
    width: 200px;
}



.Footer_divider__v8pFS {
    border-bottom:1px solid #00000020;
    width: 100%;
}

.Footer_fullContainer__13VKS .Footer_divider__v8pFS {
    border-bottom: solid 1px #ffffff20;
}


.Footer_legalNetwork__3E7Mh {
    display: flex;
    flex-direction: row;
}

.Footer_network__36cG8 {
    align-self: flex-end;
    padding: 10px;
}

.Footer_legal__1PWML {
    flex: 1 1;
    display: flex;
    padding: 10px;
    flex-direction: row;
}


.Footer_legalNetworkFooter__1tOIB {
    width: 1140px;
    display: flex;
    flex-direction: column;
    margin: 1rem auto 0;
    padding: 1rem
}


.Footer_itemLegal__1mvcy {
    margin-left: 10px;
    margin-right: 10px;
}

.Footer_itemLegal__1mvcy a {
    color: black;
    text-decoration: none;
    font-family: inherit;
    font-size: 0.85rem;
}


.Footer_icon__1clpu {
    margin-left: 15px;
    margin-right: 15px;
    color: black;
    font-size: 20px
}

.Footer_footer__3i3JL {
    padding: 10px;
    color: black;
    font-size: 0.85rem;
    flex: 1 1;
}

.Footer_footerContainer__24YKS {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Footer_co2score__1a9R5 {
    background-color: black;
    border-radius: 0.3rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.6rem;
    gap: 0.6rem;
    padding: 0.3rem 0.5rem;
    font-size: 0.8rem;
    font-family: var(--var-font-family-semi-bold);
}

@media (max-width: 780px) {
    .Footer_footer__3i3JL {
        text-align: center !important;
        font-size: 0.85rem;
    }

    .Footer_legalNetworkFooter__1tOIB {
        margin: 0;
        padding: 0;
    }

    .Footer_logoContainer__3W5a8 {
        width: unset;
    }

    .Footer_divider__v8pFS{
        display: none;
    }
    .Footer_topFooter__2prFy>div {
        width: auto;
        flex-direction: column;
    }
    .Footer_topFooter__2prFy>div>div {
        margin: 1rem 0;
    }
    
    .Footer_childTopFooter__2VOY5 {
        display: flex;
        grid-gap: 1rem;
        gap: 1rem;
    }
}

.Footer_childTopFooter__2VOY5 {
    align-items: center !important;
    justify-content: flex-end !important;
    grid-gap: 1rem;
    gap: 1rem;
}

.Footer_footerContainer__24YKS {
    display: flex;
    flex-direction: row;
}

@media (max-width: 780px) {
    .Footer_footerContainer__24YKS {
        flex-direction: column;
    }
}


.Footer_appContainer__-PIbL {
    background: #f7fafc;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 5px;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    text-decoration: none;
}

.Footer_appContainer__-PIbL {
    background: white;
}

.Footer_container__3_aI1 {
    display: flex;
    flex-direction: row;
    flex: 1 1;
}

@media (max-width: 1200px) {
    .Footer_payAndDelivery__1dOVE {
        flex-direction: column;
    }
}

.Footer_fullContainer__13VKS {
    width: 1140px;
    padding: 40px 0;
}

@media (max-width: 1200px) {
    .Footer_fullContainer__13VKS {
        width: 100%;
    }
}

@media (max-width: 770px) {
    .Footer_fullContainer__13VKS {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.Footer_legalNetwork__3E7Mh {
    display: flex;
    flex-direction: row;
}

@media (max-width: 770px) {
    .Footer_legalNetwork__3E7Mh {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.Footer_network__36cG8 {
    align-self: flex-end;
    padding: 10px;
}

@media (max-width: 770px) {
    .Footer_network__36cG8 {
        align-self: center;
    }
}

.Footer_legal__1PWML {
    flex: 1 1;
    display: flex;
    padding: 10px;
    flex-direction: row;
}

@media (max-width: 770px) {
    .Footer_legal__1PWML {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .Footer_payAndDelivery__1dOVE {
        margin: 0;
        grid-gap: 2rem;
        gap: 2rem
    }
}

.Footer_legalNetworkFooter__1tOIB {
    width: 1140px;
    display: flex;
    flex-direction: column
}

@media (max-width: 1200px) {
    .Footer_legalNetworkFooter__1tOIB {
        width: 100%;
    }
}
.Flash_flash__7I6pH {
    z-index: 1000;
    position: fixed;
    text-align: center;
    right: 1%;
    width: 33%;
}

@media (max-width: 1200px) {
    .Flash_flash__7I6pH {
        width: 50%;
    }
}

@media (max-width: 750px) {
    .Flash_flash__7I6pH {
        width: 90%;
        margin: 0 auto;
    }
}

.Flash_bulle__2p0FE {
    margin: 30px 20px;
    /*border: 0.1rem solid;*/
    background: #f3f6f8;
    font-size: 90%;
    color: black;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 90px auto;
}

.Flash_bulle__2p0FE > .Flash_iconBulle__2Ddew {
    display: flex;
    align-items: center;
    -webkit-clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    padding: 15px 25px 15px 20px;
    border-radius: 0.2rem 0 0 0.2rem;
    font-size: 200%;
    color: white;
    width: 30px;
    text-align: center;
}

.Flash_bulle__2p0FE > .Flash_iconText__3xd-k {
    padding: 15px;
    display: flex;
    align-items: center;
}

.Welcome_leftContainer__o6izK {
    background-image: url(/static/media/atorika_box.93247e4f.png);
    flex: 0.8 1;
    background-size:contain;

}
@media (max-width: 1020px) {
    .Welcome_leftContainer__o6izK {
        display: none
    }
}

.Welcome_welcomeContainer__3xN2y {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto 100px;
    max-width: 1020px;
}

@media (max-width: 1020px) {
    .Welcome_welcomeContainer__3xN2y {
        margin-top: 10px;
    }
}

.Welcome_rightContainer__W9vIX {
    flex: 1 1;
    padding-left: 40px
}

@media (max-width: 1020px) {
    .Welcome_rightContainer__W9vIX {
        padding-left: 15px;
        padding-right: 15px;
    }
}


.Welcome_inputGroup__12EHc {
    margin-left: 0;
    margin-right: 0;
}

.Welcome_checkbox__1N86v {
    margin: 0 10px 0 0;
}

.Login_loginContainer__2TZrJ {
    height: 90vh;
    margin-top: 80px;
}

/* card */

.Login_loginCard__2F22D {
    padding: 20px;
    width: 500px;
    margin: 100px 0 50px;
    top: -30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 800px) {
    .Login_loginCard__2F22D {
        width: 80%;
        margin: 20px 0 0;
    }
}

.Login_loginBtn__1ZMTe {
    margin : 20px auto;
}

.Login_linkUsePwd__3e0_- {
    margin-bottom: 40px;
}

.Login_linkRegister__3Gkca{
    margin: 5px auto;
}


/* footer */
.Login_footer__mDdDz {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* message d'erreur */
.Login_error__1a-5b {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #CE3534;
}

.Login_inputGroup__dzAqz {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
}


.Login_loaderBtn__24Ru_ {
    position: relative;
}

.Login_loaderBtn__24Ru_ div {
    color: transparent;
}

.Login_loadingLoginBtn__bO0v1 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.Home_homeContainer__1Zn4C {
    flex-direction: column;
}

@media (max-width: 960px) {
    .Home_homeContainer__1Zn4C {
        padding: 10px;
    }
}

.Home_title__36VT4 {
    align-self: flex-start;
    margin-bottom: 30px
}

.Home_cardContainer__3lWY3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 960px) {
    .Home_cardContainer__3lWY3 {
        flex-direction: column;
    }
}

.Home_addressContainer__1IZXa {
    flex: 1 1;
}

@media (max-width: 960px) {
    .Home_addressContainer__1IZXa {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}


.Home_childCard__2ByJf {
    flex: 1 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Home_break__1EDQh {
    flex-basis: 100%;
    height: 0;
}

.NotFound_container__1v_WT {
    margin: calc(75px + 9.6rem) auto 9.6rem;
    padding:0 2rem;
    max-width: 1140px;
}

.NotFound_subContainer__TMWvo {
    display: flex;
    flex-direction: row;
    grid-gap: 8rem;
    gap: 8rem;
}

.NotFound_title__7GBuI {
    font-size: 3.5rem;
    margin: 0;
}

.NotFound_subTitle__3d2PE {
    font-size: 2.4rem;
    margin: 0;
}

p {
    margin: 1rem 0 2rem;
    font-size: 1rem;
}

@media (max-width: 800px) {
    .NotFound_container__1v_WT{
        margin: calc(75px + 3rem) auto 3rem;
    }
    .NotFound_subContainer__TMWvo {
        flex-direction: column;
        text-align: center;
        grid-gap: 2rem;
        gap: 2rem;
    }
    .NotFound_subContainer__TMWvo>div{
        grid-gap: 2rem;
        gap: 2rem;
    }
    .NotFound_title__7GBuI {
        font-size: 2.2rem;
        line-height: 2.5rem;
    }
    .NotFound_subTitle__3d2PE{
        font-size: 1.7rem;
        line-height: 2rem;
    }
}
.Code_loginContainer__3S1oB {
    height: 90vh;
    margin-top: 80px;
}

select option[value=""]{
    color: #757575;
}

/* card */

.Code_loginCard__31PmJ {
    padding: 20px;
    width: 500px;
    margin: 100px 0 50px;
    top: -30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 800px) {
    .Code_loginCard__31PmJ {
        width: 80%;
    }
}

.Code_loginBtn__2oGgX {
    margin : 20px auto;
}

.Code_linkUsePwd__1j72u {
    margin-bottom: 40px;
}

.Code_linkRegister__OSV7f{
    margin: 5px auto;
}


/* footer */
.Code_footer__2JWco {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* message d'erreur */
.Code_error__3KheX {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #CE3534;
    margin: 10px 0;
}

.Code_inputGroup__3005G {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
}

.ListAddress_cardContainer__3hOz3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 1020px) {
.ListAddress_cardContainer__3hOz3 {
        flex-direction: column;
    }
}

.ListAddress_break__dTEaB {
    flex-basis: 100%;
    height: 0;
}

.ListAddress_childCard__mLO7x {
    flex: 1 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ListAddress_title__29UwJ {
    align-self: flex-start;
    margin-bottom: 30px
}
.CardTemplateBasket_controleBtn__3K-P5 {
    border-radius: 100px;
    background-color: #571088;
    color:  white;
    height: 20px;
    aspect-ratio: 1;
    text-align: center;
    margin: auto 10px;
    border: none;
    cursor: pointer;
}

.CardTemplateBasket_disabledControleBtn__jEKYZ {
    border-radius: 100px;
    background-color: #c4c4c4;
    color:  white;
    height: 20px;
    aspect-ratio: 1;
    text-align: center;
    margin: auto 10px;
    border: none
}

.CardTemplateBasket_bulle__13Lp2 {
    margin: 5px 0;
    /*border: 0.1rem solid #6fa939;*/
    background: #f3f6f8;
    font-size: 80%;
    color: black;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 1fr auto;
}

.CardTemplateBasket_bulle__13Lp2 > .CardTemplateBasket_iconBulle__1ttos {
    -webkit-clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    background: #6fa939;
    padding: 10px 25px 10px 20px;
    border-radius: 0.2rem 0 0 0.2rem;
    font-size: 150%;
    color: white;
    display: flex;
    align-items: center;
}

.CardTemplateBasket_bulle__13Lp2 > .CardTemplateBasket_iconText__1vL9_ {
    padding: 10px;
}

.CardTemplateBasket_linkTitle__1_nPv {
    text-decoration: none;
}

.CardTemplateBasket_loader__18ePy {
    width: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    -webkit-animation: CardTemplateBasket_l5__EtUTH 1s infinite linear alternate;
            animation: CardTemplateBasket_l5__EtUTH 1s infinite linear alternate;
    flex: none !important;
}
@-webkit-keyframes CardTemplateBasket_l5__EtUTH {
      0%  {box-shadow: 20px 0 #571088, -20px 0 transparent;background: #571088 }
      33% {box-shadow: 20px 0 #571088, -20px 0 transparent;background: transparent}
      66% {box-shadow: 20px 0 transparent,-20px 0 #571088; background: transparent}
      100%{box-shadow: 20px 0 transparent,-20px 0 #571088; background: #571088 }
}
@keyframes CardTemplateBasket_l5__EtUTH {
      0%  {box-shadow: 20px 0 #571088, -20px 0 transparent;background: #571088 }
      33% {box-shadow: 20px 0 #571088, -20px 0 transparent;background: transparent}
      66% {box-shadow: 20px 0 transparent,-20px 0 #571088; background: transparent}
      100%{box-shadow: 20px 0 transparent,-20px 0 #571088; background: #571088 }
}
.ListUser_cardContainer__1WX5T {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 1020px) {
.ListUser_cardContainer__1WX5T {
        flex-direction: column;
    }
}

.ListUser_break__341c0 {
    flex-basis: 100%;
    height: 0;
}

.ListUser_childCard__1x6Ah {
    flex: 1 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ListUser_title__17KKN {
    align-self: flex-start;
    margin-bottom: 30px
}

.ListUser_controleBtn__3nvIB {
    border-radius: 100px;
    background-color: #571088;
    color:  white;
    height: 20px;
    aspect-ratio: 1;
    text-align: center;
    margin: auto 10px;
    border: none
}

.ListUser_disabledControleBtn__1MKhG {
    border-radius: 100px;
    background-color: #c4c4c4;
    color:  white;
    height: 20px;
    aspect-ratio: 1;
    text-align: center;
    margin: auto 10px;
    border: none
}
.Profile_homeContainer__cNbUF {
    flex-direction: column;
}

@media (max-width: 960px) {
    .Profile_homeContainer__cNbUF {
        padding: 10px;
    }
}

.Profile_title__2fmPa {
    align-self: flex-start;
    margin-bottom: 30px
}

.Profile_cardContainer__1ywhW {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 960px) {
    .Profile_cardContainer__1ywhW {
        flex-direction: column;
    }
}

.Profile_addressContainer__2Ykfu {
    flex: 1 1;
}

@media (max-width: 960px) {
    .Profile_addressContainer__2Ykfu {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}


.Profile_childCard__p5ptM {
    flex: 1 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Profile_break__12fxS {
    flex-basis: 100%;
    height: 0;
}

.ResumeBasket_scroll__3LgiW::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #ddd;
    border-radius: 5px;
}

.ResumeBasket_scroll__3LgiW::-webkit-scrollbar-thumb {
    background: #571088;
    border-radius: 5px;
}

.ResumeBasket_containerPromoPrice__3NYKL {
    display: flex;
    flex-direction: column;
}

.ResumeBasket_containerPromoPrice__3NYKL>div {
    width: 100%;
}

.ResumeBasket_containerTextIcon__hyrD5 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.ResumeBasket_retrait__r8BIA svg {
    width: 25px !important;
}

.ResumeBasket_nombreArticle__BvPsi {
    margin-top: 5rem;
    text-align: right;
    position: relative;
}

.ResumeBasket_nombreArticle__BvPsi:after {
    content: '';
    height: 2px;
    width: 50%;
    position: absolute;
    right: 0;
    top: 31px;
    background-color: rgb(223, 226, 228);
    opacity: 0.8;
    border-radius: 20px;
}
.UnloggedValidateBasket_openStat__2EuPG {
    width: 40px;
    height: 40px;
    transition: width 1s ease-in-out, height 1s ease-in-out;
    border-radius: 50px;
    background-color: rgb(224, 224, 224);
    display: flex;
    align-items: center;
    justify-content: center;
}

.UnloggedValidateBasket_cardStat__14G_V {
    overflow: hidden;
    width: 400px;
    height: 200px;
    border-radius: 15px;
    background-color: rgb(224, 224, 224);
    transition: width 1s ease-in-out, height 1s ease-in-out;
}

.UnloggedValidateBasket_containerPopUp__1G-f1 {
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    bottom: 445px;
    right: 50px;
    cursor: pointer;
}

.UnloggedValidateBasket_closeButton__3OXBY {
    position: absolute;
    right: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 6px;
    color: var(--var-black-color);
}


.UnloggedValidateBasket_titlePopUp__2IvlH {
    text-align: center;
}

.UnloggedValidateBasket_cardPrice__1MMY1 {
    flex: .8 1 !important;
    grid-gap: .5rem !important;
    gap: .5rem !important;
}

.UnloggedValidateBasket_containerPrice__242iV {
    display: flex;
    grid-gap: .5rem;
    gap: .5rem;
}

.UnloggedValidateBasket_titleFraiLivraison__1YEP0 {
    font-weight: 700;
    display: flex;
    grid-gap: .5rem;
    gap: .5rem;
    display: flex;
    justify-content: space-between;
}

.UnloggedValidateBasket_containerPriceLivraison__hA3aP {
    display: flex;
    flex-direction: row;
    grid-gap: 30px;
    gap: 30px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.UnloggedValidateBasket_containerPrix__jBklq {
    justify-content: flex-start !important;
    display: flex !important;
    flex-direction: column !important;
    text-align: left !important;
}

.UnloggedValidateBasket_price__1WIND {
    display: flex;
    grid-gap: .3rem;
    gap: .3rem;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 700;
}

.UnloggedValidateBasket_priceLivraison__2g64R {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: 500;
}

.UnloggedValidateBasket_retrait__39uRr {
    display: flex;
    justify-content: space-between;
}

.UnloggedValidateBasket_retrait__39uRr svg {
    width: 25px !important;
}

.UnloggedValidateBasket_sousTexte__3VO5g {
    margin: 1rem 0;
}

.UnloggedValidateBasket_greenText__2FD9U {
    color: #6fa939;
}

.UnloggedValidateBasket_customSelect__3JsVI {
    width: 150px;
    padding: 3px;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.25rem;

}

.UnloggedValidateBasket_containerStep__W22jV {
    min-width: 50px;
}

.UnloggedValidateBasket_basketItems__Hf9Gx {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    gap: 20px;
    margin-top: 20px;
}

.UnloggedValidateBasket_basketItems__Hf9Gx>div:nth-child(2n-1):last-of-type {
    grid-column: 1 / -1;
  }

.UnloggedValidateBasket_centimePrice__XYqgB {
    font-size: .9rem;
}

.UnloggedValidateBasket_nbArticle__1QxGy {
    position: relative;
    width: 100%;
    text-align: right;
    margin-top: 1rem;
}

.UnloggedValidateBasket_nbArticle__1QxGy:after {
    content: '';
    height: 2px;
    width: 50%;
    position: absolute;
    right: 0;
    top: 37px;
    background-color: rgb(223, 226, 228);
    opacity: 0.8;
    border-radius: 20px;
}

.UnloggedValidateBasket_containerTextIcon__mVUzL {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

@media screen and (max-width: 768px) {
    .UnloggedValidateBasket_containerPriceLivraison__hA3aP {
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
    }

    .UnloggedValidateBasket_basketItems__Hf9Gx {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 0;
    }

    .UnloggedValidateBasket_cardPrice__1MMY1 {
        align-items: flex-start !important;
    }

    .UnloggedValidateBasket_nbArticle__1QxGy {
        text-align: start;
    }

    .UnloggedValidateBasket_nbArticle__1QxGy:after {
        right: auto;
        left: 0;
    }

    .UnloggedValidateBasket_titleFraiLivraison__1YEP0 {
        flex-direction: column;
    }

    .UnloggedValidateBasket_retrait__39uRr {
        align-items: center;
        margin-left: -7px;
        margin-right: -3px;
    }

    .UnloggedValidateBasket_priceLivraison__2g64R{
        font-size: 1rem;
    }
}

.ListPoints_listpoints__3MdrI::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #ddd;
    border-radius: 5px;
}

.ListPoints_listpoints__3MdrI::-webkit-scrollbar-thumb {
    background: #571088;
    border-radius: 5px;
}

.Delivery_cardContainer__VfQ6X {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
}
.Delivery_currentRelay__2pwB3{
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

@media screen and (max-width: 768px) {
    .Delivery_currentRelay__2pwB3{
        position:unset;
        flex-flow: row-reverse;
    }
}
.Payment_cardContainer__bFMbH {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

.Payment_subContainer__zZQ5B {
    margin: 10px;
    display: flex;
}

@media (max-width: 800px) {
    .Payment_subContainer__zZQ5B {
        flex-direction: column;
    }
}

.Payment_infos__3mdQk {
    padding-left: 20px;
    display: flex;
    justify-content: center;
}

.Payment_title__2kS7M {
    align-self: flex-start;
    margin-bottom: 30px
}

.Payment_text__3D26N {
    margin: 40px auto;
    font-size: 1.2rem;
}

.Payment_paymentCardImg__3fX3L {
    object-fit: contain;
    height: 80%;
    width: 80%;
}

.Payment_containerCardImg__1MDeN {
    border: 1px solid rgb(206, 212, 218);
    padding: 0 4px;
    width: auto;
    aspect-ratio: 16 / 9;
    border-radius: 4px;
    height: 1.8rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Box_cardContainer__2J8W3 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.Box_cardContainer__2J8W3>div:nth-child(1):first-of-type {
    grid-column: 1 / -1;
}

@media (max-width: 960px) {
    .Box_cardContainer__2J8W3 {
        grid-template-columns: repeat(1, 1fr);
    }
}

.CardStats_stats__1r6TB {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    max-width: 200px;
}

.CardStats_statsStar__2WpWU {
    background: url(/static/media/Stats_star.87c11ab6.svg) no-repeat;
    aspect-ratio: 356/200;
    background-size: contain;
}

.CardStats_statsCheck__3Aw2_ {
    background: url(/static/media/Stats_check.53e089ee.svg) no-repeat;
    aspect-ratio: 354/196;
    background-size: contain;
}

.CardStats_statsReward__2r493 {
    background: url(/static/media/Stats_reward.46809ddc.svg) no-repeat;
    aspect-ratio: 354/196;
    background-size: contain;
}

.CardStats_statsQuiz__273yz {
    background: url(/static/media/Stats_ask.efcf6106.svg) no-repeat;
    aspect-ratio: 354/196;
    background-size: contain;
}

.CardStats_statsDigit__26NHX {
    font-size: 1.7rem;
    text-align: center;
    margin-top: 20px;
    margin-left: 35px;
    margin-right: 20px;
}

@media (max-width: 960px) {
    .CardStats_statsDigit__26NHX {
        font-size: 1.7rem;
    }
}

.CardStats_statsName__1376c {
    font-size: 0.8rem;
    text-align: center;
    margin-left: 35px;
    margin-right: 20px;
    color: #C4C4C4;
    font-weight: 300;
}

@media (max-width: 565px) {
    .CardStats_statsName__1376c {
        font-size: 0.9rem;
    }
}

.CardStats_badge__34bk7 {
    aspect-ratio: 1;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    width: 100%;
    margin: auto
}

.CardStats_badgeName__1PQMm {
    margin-top: 5px;
    font-size: 1rem;
    text-align: center
}

.CardStats_badgeInterr__33oZq {
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
    margin: 0;
}

.CardStats_badgeContainer__27bFj {
    flex: 1 1;
    margin: 0 15px;
    flex-direction: column;
    align-items: center;
}

