.cardContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.cardContainer>div:nth-child(1):first-of-type {
    grid-column: 1 / -1;
}

@media (max-width: 960px) {
    .cardContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}
