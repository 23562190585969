.scroll::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #ddd;
    border-radius: 5px;
}

.scroll::-webkit-scrollbar-thumb {
    background: #571088;
    border-radius: 5px;
}

.containerPromoPrice {
    display: flex;
    flex-direction: column;
}

.containerPromoPrice>div {
    width: 100%;
}

.containerTextIcon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.retrait svg {
    width: 25px !important;
}

.nombreArticle {
    margin-top: 5rem;
    text-align: right;
    position: relative;
}

.nombreArticle:after {
    content: '';
    height: 2px;
    width: 50%;
    position: absolute;
    right: 0;
    top: 31px;
    background-color: rgb(223, 226, 228);
    opacity: 0.8;
    border-radius: 20px;
}