.title {
    color: white;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.2em;
    margin-bottom: 20px
}

.item {
    padding-left: 6px;
    padding-bottom: 5px;
    font-size: 1.1rem;
    margin: 5px auto;
    color: #DDDDDD;
    text-decoration: none;
}

.link {
    color: white;
    text-decoration: none;
}

.itemLegal {
    margin-left: 10px;
    margin-right: 10px;
}

.linkLegal {
    color: white;
    text-decoration: none;
}

.icon {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    font-size: 20px
}

.footer {
    padding: 10px;
    color: white;
    font-size: 0.85rem;
    flex: 1;
}


.topFooter {
    background-color: #f7fafc;
    margin-top: 4rem;
}

.topFooter>div {
    display: flex;
    width: 1140px;
    margin: auto;
    padding: 2rem 0;
}


.topFooter>div>div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: var(--var-font-family-semi-bold);
}

.topFooter>div>div>div {
    display: flex;
    align-items: flex-end;
    font-weight: 500;
}

.france {
    aspect-ratio: 1;
    background-size: contain;
    width: 70px;
    background-repeat: no-repeat;
    background-position-y: bottom;
}

.payAndDelivery {
    color: white;
    display: flex;
    margin: 1rem 10px;
    font-size: 1.3rem;
    font-family: var(--var-font-family-semi-bold);
}

.logoContainer {
    display: flex;
    width: 1140px;
}

.logoContainer>img {
    margin-left: 10px;
}

.logoContainer img {
    width: 200px;
}



.divider {
    border-bottom:1px solid #00000020;
    width: 100%;
}

.fullContainer .divider {
    border-bottom: solid 1px #ffffff20;
}


.legalNetwork {
    display: flex;
    flex-direction: row;
}

.network {
    align-self: flex-end;
    padding: 10px;
}

.legal {
    flex: 1;
    display: flex;
    padding: 10px;
    flex-direction: row;
}


.legalNetworkFooter {
    width: 1140px;
    display: flex;
    flex-direction: column;
    margin: 1rem auto 0;
    padding: 1rem
}


.itemLegal {
    margin-left: 10px;
    margin-right: 10px;
}

.itemLegal a {
    color: black;
    text-decoration: none;
    font-family: inherit;
    font-size: 0.85rem;
}


.icon {
    margin-left: 15px;
    margin-right: 15px;
    color: black;
    font-size: 20px
}

.footer {
    padding: 10px;
    color: black;
    font-size: 0.85rem;
    flex: 1;
}

.footerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.co2score {
    background-color: black;
    border-radius: 0.3rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    padding: 0.3rem 0.5rem;
    font-size: 0.8rem;
    font-family: var(--var-font-family-semi-bold);
}

@media (max-width: 780px) {
    .footer {
        text-align: center !important;
        font-size: 0.85rem;
    }

    .legalNetworkFooter {
        margin: 0;
        padding: 0;
    }

    .logoContainer {
        width: unset;
    }

    .divider{
        display: none;
    }
    .topFooter>div {
        width: auto;
        flex-direction: column;
    }
    .topFooter>div>div {
        margin: 1rem 0;
    }
    
    .childTopFooter {
        display: flex;
        gap: 1rem;
    }
}

.childTopFooter {
    align-items: center !important;
    justify-content: flex-end !important;
    gap: 1rem;
}

.footerContainer {
    display: flex;
    flex-direction: row;
}

@media (max-width: 780px) {
    .footerContainer {
        flex-direction: column;
    }
}


.appContainer {
    background: #f7fafc;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 5px;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    text-decoration: none;
}

.appContainer {
    background: white;
}

.container {
    display: flex;
    flex-direction: row;
    flex: 1;
}

@media (max-width: 1200px) {
    .payAndDelivery {
        flex-direction: column;
    }
}

.fullContainer {
    width: 1140px;
    padding: 40px 0;
}

@media (max-width: 1200px) {
    .fullContainer {
        width: 100%;
    }
}

@media (max-width: 770px) {
    .fullContainer {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.legalNetwork {
    display: flex;
    flex-direction: row;
}

@media (max-width: 770px) {
    .legalNetwork {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.network {
    align-self: flex-end;
    padding: 10px;
}

@media (max-width: 770px) {
    .network {
        align-self: center;
    }
}

.legal {
    flex: 1;
    display: flex;
    padding: 10px;
    flex-direction: row;
}

@media (max-width: 770px) {
    .legal {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .payAndDelivery {
        margin: 0;
        gap: 2rem
    }
}

.legalNetworkFooter {
    width: 1140px;
    display: flex;
    flex-direction: column
}

@media (max-width: 1200px) {
    .legalNetworkFooter {
        width: 100%;
    }
}